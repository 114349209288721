import { Button, Image, Table, Tag, Tooltip, Select, Input } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import EditIcon from "../../assets/images/edit.svg";
import deleteWhiteIcon from "../../assets/images/icon/deleteWhiteIcon.png";
import notfound from "../../assets/images/not_found.png";
import Plus from "../../assets/images/plus.svg";
import DeleteModal from "../../components/DeleteModal";
import SectionWrapper from "../../components/SectionWrapper";
import apiPath from "../../constants/apiPath";
import { DeleteBanner } from "../../constants/var";
import { useAppContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import AdvertisementBannerForm from "./AdvertisementBannerForm";
import ConfirmationBox from "../../components/ConfirmationBox";
import { UndoOutlined } from "@ant-design/icons";
import { Months } from "../../constants/var";
import useDebounce from "../../hooks/useDebounce";

const BannerManager = ({ sectionName }) => {
  const { country } = useAppContext();
  const [pagination, setPagination] = useState({
    current: 1,
    total: 10,
  });

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [showDelete, setShowDelete] = useState(false);
  const { showConfirm } = ConfirmationBox();
  const [selectedAddBanner, setSelectedAddBanner] = useState();
  const [visible, setVisible] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selected, setSelected] = useState();
  const [showStatus, setShowStatus] = useState(false);
  const { request } = useRequest();
  const debouncedSearchText = useDebounce(searchText, 300);
  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);

  const urlParams = new URLSearchParams(window.location.search);
  const year = urlParams.get("year");
  const [cities, setCities] = useState([]);
  const [filter, setFilter] = useState({
    country_id: undefined,
    city_id: undefined,
    banner_for: undefined,
    year: year,
    month: undefined,
  });

  const api = {
    status: apiPath.banner,
  };

  const handleChange = (pagination, sorter, filters) => {
    if (!sorter) {
      setPagination((prev) => ({
        current: pagination.current,
        total: 10,
      }));
    }
  };

  const onDelete = (record) => {
    request({
      url: apiPath.banner + "/" + record?._id,
      method: "DELETE",
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }

        fetchData(pagination);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const getFilter = () => {
    request({
      url: `${apiPath.banner}/filters`,
      method: "GET",
      onSuccess: (res) => {
        const { data, months, years } = res;
        setCities(data);
        setYears(years);
        const m = Months.filter((item) => months.includes(item.value));
        setMonths(m);
      },
    });
  };

  // const handleChangeStatus = (id) => {
  //   request({
  //     url: api.status + id + "/status",
  //     method: "PUT",
  //     onSuccess: (data) => {
  //       setLoading(false);
  //       setRefresh((prev) => !prev);
  //     },
  //     onError: (error) => {
  //       console.log(error);
  //       setLoading(false);
  //       ShowToast(error, Severty.ERROR);
  //     },
  //   });
  // };

  const queryString = Object.entries(filter)
    .filter(([_, v]) => v)
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
    )
    .join("&");

  const fetchData = () => {
    request({
      url:
        apiPath.banner +
        `?status=${""}&page=${pagination ? pagination.current : 1}&pageSize=${
          pagination && pagination?.total ? pagination?.total : 10
        }&search=${debouncedSearchText}${queryString ? `&${queryString}` : ""}`,
      method: "GET",
      onSuccess: (data) => {
        setList(data.data);
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  const columns = [
    {
      title: lang("Banner"),
      dataIndex: "image",
      key: "image",
      render: (_, { image }) => (
        <Image
          width={50}
          src={image ? image : notfound}
          className="table-img"
        />
      ),
    },
    {
      title: lang("Start Date"),
      dataIndex: "start_date",
      key: "start_date",
      render: (_, { start_date }) => {
        return moment(start_date).format("DD-MMM-YYYY");
      },
    },
    {
      title: lang("End Date"),
      dataIndex: "end_date",
      key: "end_date",
      render: (_, { end_date }) => {
        return moment(end_date).format("DD-MMM-YYYY");
      },
    },
    {
      title: lang("Banner For"),
      dataIndex: "company",
      key: "name",
      render: (_, { banner_for }) => {
        return banner_for ? <span className="cap">{banner_for}</span> : "-";
      },
    },
    {
      title: lang("Advertisement Company Name"),
      dataIndex: "company",
      key: "name",
      render: (_, { name, restaurant_id }) => {
        return name ? (
          <span className="cap">{name}</span>
        ) : restaurant_id ? (
          <span className="cap">{restaurant_id.name}</span>
        ) : (
          "-"
        );
      },
    },
    {
      title: lang("Contact Details"),
      dataIndex: "contact_details",
      key: "contact_details",
      render: (_, { country_code, mobile_number, restaurant_id }) => {
        return country_code && mobile_number
          ? "+" + country_code + " " + mobile_number
          : restaurant_id
          ? "+" + restaurant_id.country_code + " " + restaurant_id.mobile_number
          : "-";
      },
    },
    // {
    //   title: "Company Category",
    //   dataIndex: "category_id",
    //   key: "category_id",
    //   render: (_, { category_id }) => {
    //     return category_id ? <span className="cap">{category_id}</span> : "-";
    //   },
    // },
    {
      title: lang("Banner Position"),
      dataIndex: "banner_position",
      key: "banner_position",
      render: (_, { position }) => {
        return position ? <span className="cap">{lang(position)}</span> : "-";
      },
    },
    {
      title: lang("Cities"),
      dataIndex: "city_id",
      key: "city_ids",
      render: (_, { city_ids }) => {
        return city_ids.length > 0 ? (
          <span className="cap">{city_ids.map((el) => el.name).join(",")}</span>
        ) : (
          "-"
        );
      },
    },
    // {
    //   title: lang("URL"),
    //   dataIndex: "link",
    //   key: "link",
    //   render: (_, { link }) => {
    //     return link ? (
    //       <Link style={{}} to={link} target="_blank">
    //         {link}
    //       </Link>
    //     ) : (
    //       "-"
    //     );
    //   },
    // },
    {
      title: lang("Status"),
      key: "is_active",
      filters: [
        {
          text: "Active",
          value: true,
        },
        {
          text: "Inactive",
          value: false,
        },
      ],
      render: (_, { is_active, _id }) => {
        let color = is_active ? "green" : "red";
        return (
          <a>
            <Tag
              // onClick={(e) =>
              //   showConfirm({
              //     record: _id,
              //     path: api.status + "/status",
              //     onLoading: () => setLoading(true),
              //     onSuccess: () => setRefresh((prev) => !prev),
              //   })
              // }
              color={color}
              key={is_active}
            >
              {is_active ? "Active" : "Inactive"}
            </Tag>
          </a>
        );
      },
    },
    {
      title: lang("Action"),
      fixed: "right",
      key: "action",
      render: (_, record) => {
        return (
          <div div className="d-flex justify-contenbt-start">
            <Tooltip title={lang("Edit")} color={"purple"} key={"update"}>
              <Button
                title={lang("Edit")}
                className="edit-cls btnStyle primary_btn"
                onClick={() => {
                  setSelectedAddBanner(record);
                  setVisible(true);
                }}
              >
                <img src={EditIcon} />
                <span>{lang("Edit")}</span>
              </Button>
            </Tooltip>

            <Tooltip title={lang("Delete")} color={"purple"} key={"Delete"}>
              <Button
                title={lang("Delete")}
                className="btnStyle deleteDangerbtn"
                onClick={(e) => {
                  setSelectedAddBanner(record);
                  setShowDelete(true);
                }}
              >
                <img src={deleteWhiteIcon} />
                <span>{lang("Delete")}</span>
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    fetchData(pagination);
    getFilter();
  }, [refresh, country.country_id, filter, debouncedSearchText]);

  const onChange = (key, value) => {
    if (key == "country_id") {
      setCities([]);
      setFilter((prev) => ({ ...prev, city_id: undefined, country_id: value }));
    } else {
      setFilter((prev) => ({ ...prev, [key]: value }));
    }
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <>
      <SectionWrapper
        cardHeading={lang("Advertisement Manager")}
        extra={
          <>
            <div className="w-100 text-head_right_cont">
              <div className="pageHeadingSearch">
                <Input.Search
                  className="searchInput"
                  placeholder={lang("Search by Banner for, Company name")}
                  onChange={onSearch}
                  allowClear
                />
              </div>
              <div className="role-wrap">
                <Select
                  width="110px"
                  placeholder={lang("City")}
                  showSearch
                  value={filter.city_id}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={cities.map((item) => ({
                    value: item._id,
                    label: item.name,
                  }))}
                  onChange={(value) => onChange("city_id", value)}
                />
              </div>
              <div className="role-wrap">
                <Select
                  placeholder={lang("Banner For")}
                  className="w-100"
                  onChange={(value) => onChange("banner_for", value)}
                  value={filter.banner_for}
                >
                  <option value={"company"}>
                    <span className="cap">{lang("Company")}</span>
                  </option>
                  <option value={"app"}>
                    <span className="cap">{lang("App")}</span>
                  </option>
                </Select>
              </div>
              <Button
                onClick={() =>
                  setFilter({
                    country_id: undefined,
                    city_id: undefined,
                    year: undefined,
                    month: undefined,
                  })
                }
                type="primary"
                icon={<UndoOutlined />}
              >
                {lang("Reset")}
              </Button>
              {/* <div className="pageHeadingSearch">
                <Input.Search
                  className="searchInput"
                  placeholder={lang("Search by advertisement")}
                  // onChange={onSearch}
                  allowClear
                />
              </div> */}

              <Button
                className="primary_btn btnStyle"
                onClick={(e) => {
                  setVisible(true);
                }}
              >
                <span className="add-Ic">
                  <img src={Plus} />
                </span>
                {lang("Add Banner")}
              </Button>
            </div>
          </>
        }
      >
        <div className="table-responsive customPagination">
          <Table
            loading={loading}
            columns={columns}
            dataSource={list}
            pagination={{
              defaultPageSize: 10,
              responsive: true,
              total: pagination.total,
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
            onChange={handleChange}
            className="ant-border-space"
          />
        </div>
      </SectionWrapper>

      {visible && (
        <AdvertisementBannerForm
          type={sectionName}
          sectionName={sectionName}
          show={visible}
          hide={() => {
            setSelectedAddBanner();
            setVisible(false);
          }}
          ant-btn-default
          data={selectedAddBanner}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}

      {showDelete && (
        <DeleteModal
          title={lang("Delete Banner")}
          subtitle={lang(`Are you sure you want to Delete this Banner?`)}
          show={showDelete}
          hide={() => {
            setShowDelete(false);
            setSelectedAddBanner();
          }}
          reasons={DeleteBanner}
          onOk={() => onDelete(selectedAddBanner)}
        />
      )}

      {/* {showStatus && (
        <DeleteModal
          title={`${selected?.is_active ? `Block` : `UnBlock`} User`}
          subtitle={`Are you sure you want to ${selected?.is_active ? `block` : `unblock`
            } this user?`}
          show={showStatus}
          hide={() => {
            setShowStatus(false);
            setSelected();
          }}
          onOk={() => handleChangeStatus(selected?._id)}
        />
      )} */}
    </>
  );
};

export default BannerManager;
