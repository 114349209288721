import {
    Button,
    Input,
    Modal,
    Table,
    Tabs,
    Tooltip
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import Lottie from "react-lottie";
import * as success from '../../assets/animation/success.json';
import Currency from "../../components/Currency";
import apiPath from "../../constants/apiPath";
import { AppStateContext } from "../../context/AppContext";
import { dateString } from "../../helper/functions";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";

const Search = Input.Search;
const { TabPane } = Tabs;

const DriverPayment = ({ }) => {

    const { country } = useContext(AppStateContext);

    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const { request } = useRequest();
    const api = {
        status: apiPath.statusQuote,
        list: apiPath.finance + '/driver/withdraw/approved',
    };

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });

    const handleChange = (pagination, sorter, filters) => {
        // if (!sorter) {
            fetchData(pagination);
        // }
    };

    const fetchData = (pagination, status) => {

        setLoading(true);

        const payload = {};
        payload.page = pagination ? pagination.current : 1;
        payload.pageSize = pagination ? pagination?.pageSize : 10;

        const queryString = Object.entries(payload).filter(([_, v]) => v).map(
            ([key, value]) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
        )
            .join("&");

        request({
            url: api.list + `${queryString ? `?${queryString}` : ""}`,
            method: "GET",
            onSuccess: ({ data, total, status }) => {
                setLoading(false);
                if (status) {
                    setList(data);
                    setPagination((prev) => ({
                        ...prev,
                        current: pagination.current,
                        total: total,
                    }));
                }

            },
            onError: (error) => {
                setLoading(false);
                ShowToast(error, Severty.ERROR);
            },
        });
    };


    useEffect(() => {
        setLoading(true);
        fetchData({current:1});
    }, [refresh, country.country_id]);

    const columns = [
        {
            title: lang("Driver. ID"),
            dataIndex: "id",
            render: (_, { user_id }) => `#${user_id ? user_id.uid : ''}`,
        },
        {
            title: lang("NAME"),
            dataIndex: "name",
            key: "name",
            render: (_, { user_id }) => `${user_id ? user_id.name : ''}`,
        },
        {
            title: lang("withdrawal Date"),
            dataIndex: "dateTime",
            key: "dateTime",
            render: (_, { created_at }) => `${dateString(created_at, 'll')}`,
        },
        {
            title: lang("Requested withdrawal Amount"),
            dataIndex: "withdrawalAmount",
            key: "withdrawalAmount",
            render: (_, { amount }) => <Currency price={amount ?? 0} />,
        },
        {
            title: lang("Reason"),
            dataIndex: "Reason",
            key: "Reason",
            render: (_, { reason }) => reason,
        },
        {
            title: lang("Driver Wallet Balance"),
            dataIndex: "WalletBalance",
            key: "WalletBalance",
            render: (_, { wallet }) => <Currency price={wallet?.balance ?? 0} />,
        },

        {
            title: lang("Action"),
            fixed: "right",
            render: (_, record) => {
                return (
                    <>
                        <Button
                            className="ms-sm-2 mt-xs-2 primary_btn btnStyle"
                        >
                            {lang("Paid")}
                        </Button>
                    </>
                );
            },
        },
    ];


    return (
        <>
            <div className="tab_inner_tit">
                <div className="tab-upload-wrap d-flex align-items-center justify-content-between">
                    <h3>{lang("Drivers Payment History")}</h3>
                </div>
            </div>


            <div className="table-responsive customPagination withOutSearilNo">
                <Table
                    loading={loading}
                    columns={columns}
                    dataSource={list}
                    pagination={{
                        defaultPageSize: 10,
                        responsive: true,
                        total: pagination.total,
                        showSizeChanger: true,
                        pageSizeOptions: ["10", "20", "30", "50"],
                    }}
                    onChange={handleChange}
                    className="ant-border-space"
                />
            </div>


        </>
    )
}


export default DriverPayment