import { Button, Col, Image, Modal, Row } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { formatPhone } from '../../helper/functions'
import notfound from "../../assets/images/not_found.png";
import apiPath from "../../constants/apiPath";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import lang from "../../helper/langHelper";

export const ApproveStatus = {
  REJECT: "rejected",
  ACCEPT: "accepted",
  SUSPENDED: "suspended",
  PENDING: "pending",
};

const ViewNewRestaurantRequest = ({ section, api, show, hide, data, refresh }) => {
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);

  const handleApproveReject = (status) => {
    setLoading(true);
    request({
      url: apiPath.restaurant + "/" + data?._id + "/action?status=" + status,
      method: "PUT",
      data: { status: status },
      onSuccess: (data) => {
        ShowToast(data.message, Severty.SUCCESS);
        refresh();
        setLoading(false);
        hide();
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  return (
    <Modal
      open={show}
      width={750}
      okText="Add"
      onCancel={hide}
      footer={[
        (data.approve_status != ApproveStatus.REJECT) ? <Button
          key="reject"
          onClick={() => {
            handleApproveReject(ApproveStatus.REJECT);
          }}
        >
          
          {lang(`Reject`)}

        </Button> :
          <Button
            key="reject"
            onClick={() => {
              hide()
            }}
          >
            {lang(`Cancel`)}
          </Button>,
        <Button
          key="approve"
          type="primary"
          onClick={() => {
            handleApproveReject(ApproveStatus.ACCEPT);
          }}
          loading={loading}
        >
          {lang(`Approve`)}
          
        </Button>,
      ]}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="tab_modal driver-modal"
    >
      <div className="modal_title_wrap">
        <h4 className="modal_title_cls">{lang("New Restaurant request")}</h4>
        <p>{lang("New Restaurant has been registered with the app")}.</p>
      </div>
      <div className="driver_img">
        <Image
          src={data?.logo ? data?.logo : notfound}
          className="table-img"
          style={{ width: "70px", height: "70px" }}
        />
      </div>
      <div className="new_driver_dtl">
        <Row
          gutter={24}
        >
          <Col span={24} sm={6} md={8}>
            <p>Restaurant Name:</p>
            <p className="font-bold">{data.name}</p>
          </Col> 
          <Col span={24} sm={6} md={8}>
            <p>Restaurant Name Arabic</p>
            <p className="font-bold">{data.ar_name}</p>
          </Col>

          <Col span={24} sm={8} md={8}>
            <p>Phone Number:</p>
            <p className="font-bold">{data?.country_code ? formatPhone(data?.country_code, data?.mobile_number) : ''}</p>
          </Col>
        </Row>
      </div>
      
      <div className="new_driver_dtl">
        <Row
          gutter={24} >
          <Col span={24} sm={6} md={8}>
            <p>Email Address:</p>
            <p className="font-bold">{data?.email}</p>
          </Col>
          <Col span={24} sm={6} md={8}>
            <p>Country:</p>
            <p className="font-bold">{data?.country_id?.name}</p>
          </Col>
          <Col span={24} sm={6} md={8}>
            <p>City:</p>
            <p className="font-bold">{data?.city_id?.name}</p>
          </Col>
        </Row>
      </div>
      <div className="driver-id-dtl">
        <Row
          gutter={24}
        >
          {data?.document.map((item, idx) =>
            <Col span={12} sm={12} md={6} key={item}>
              <p>Document {idx + 1}:</p>
              <Image
                src={item}
                style={{ width: "100px", height: "70px" }}
              />
            </Col>)}


        </Row>
      </div>

    </Modal>
  );
};

export default ViewNewRestaurantRequest;
