export const Last20Years = Array.from({ length: 20 }, (_, index) =>
  (new Date().getFullYear() - index).toString(),
);
export const Months = [
  { label: "January", value: 1 },
  { label: "February", value: 2 },
  { label: "March", value: 3 },
  { label: "April", value: 4 },
  { label: "May", value: 5 },
  { label: "June", value: 6 },
  { label: "July", value: 7 },
  { label: "August", value: 8 },
  { label: "September", value: 9 },
  { label: "October", value: 10 },
  { label: "November", value: 11 },
  { label: "December", value: 12 },
];

export const BlockRest = [
  "Quality and Safety Issues",
  "Chronic Order Delays",
  "Misrepresentation",
  "Violation of Platform Policies",
  "Illegal Activities",
];

export const BlockDriver = [
  "Consistent Order Delays",
  "Poor Customer Service",
  "Reckless Driving",
  "Fraudulent Activity",
  "Violation of Platform Policies",
];

export const CancelOrder = [
  "Payment Issues",
  "Unavailability of Items",
  "Delivery Address Inaccuracy",
  "Security or Fraud Concerns",
  "Violation of Platform Policies",
  "Customer Not Accept",
  "Customer Absent",
];

export const BlockSubAdmin = [
  "Violation of Company Policies",
  "Breach of Security Protocols",
  "Misuse of Administrative Privileges",
  "Failure to Fulfill Assigned Responsibilities",
  "Engagement in Unethical Behavior",
];

export const DeleteBanner = [
  "Expired Promotion or Event",
  "Incorrect Information",
  "Inappropriate Content",
  "Rebranding or Campaign Change",
  "Design or Layout Issues",
];

export const rolesOptions = [
  { name: "dashboard-management", label: "Dashboard Management" },
  { name: "category-management", label: "Category Management" },
  { name: "restaurant-manager", label: "Restaurant Management" },
  { name: "customer-manager", label: "Customer Management" },
   { name: "discount-manager", label: "Discount Management" },
  { name: "order-manager", label: "Order Management" },
  { name: "driver-manager", label: "Driver Management" },
   { name: "advertise-manager", label: "Advertise Management" },
  { name: "cms-manager", label: "CMS Management" },
  { name: "delivery-manager", label: "Delivery History Management" },
  { name: "rating-manager", label: "Rating and Reviews Management" },
  { name: "report-manager", label: "Reports Management" },
   { name: "companies-manager", label: "Company management" },
  // { name: "email-template-manager", label: "Email Template Management" },
  { name: "finance-manager", label: "Financial Management" },
  { name: "collector-manager", label: "Collection Management" },
  { name: "service-location-manager", label: "Service Location  Management" },
  { name: "delivery-charge-manager", label: "Delivery charge  Management" },
];

export const Palestine = {
  id: "646b2e0f46865f1f65565346",
  name: "Palestine",
};
export const defaultSize = {
  _id: "6595594c33a718e860e10342",
  name: "Regular",
  ar_name: "عادي",
};
