import React, { useEffect, useState, useContext } from "react";
import { Card, Typography } from "antd";
import { AppStateContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import LoyaltyForm from "../../components/LoyaltyForm";

const { Title } = Typography;

const LoyaltyPoints = () => {
  const { setPageHeading } = useContext(AppStateContext);
  const { request } = useRequest();
  const [formValues, setFormValues] = useState({
    conversionRate: "",
    deliveryConversionRate: "",
    expiryTime: "",
  });
  const [loyaltyPoints, setLoyaltyPoints] = useState(null);

  useEffect(() => {
    // setPageHeading(lang("Loyalty Points"));
    console.log("----formValues", formValues);
    fetchLoyaltyPoints();
  }, [setPageHeading]);

  const fetchLoyaltyPoints = async () => {
    request({
      url: "/admin/loyalty-points",
      method: "get",
      onSuccess: (response) => {
        console.log("API response:", response);
        if (response && response.data) {
          setLoyaltyPoints(response.data);
          setFormValues({
            id: response.data._id,
            conversionRate: response.data.conversionRate,
            deliveryConversionRate: response.data.deliveryConversionRate,
            expiryTime: response.data.expiryTime,
            refer_point:response.data.refer_point
          });
        } else {
          console.error("Unexpected API response format:", response);
          ShowToast(lang("Unexpected API response format"), Severty.ERROR);
        }
      },
      onError: (error) => {
        console.error("API error:", error); // Log the error details
        ShowToast(lang("Error fetching loyalty points"), Severty.ERROR);
      },
    });
  };

  const handleLoyaltySubmit = async (values) => {
    try {
      const { id, ...loyaltyData } = values;
      console.log("Submitting loyalty data:", { id, ...loyaltyData }); // Log the data
      let response;
      if (id) {
        console.log("working-------");
        response = await request({
          url: `/admin/loyalty-points/${id}`,
          method: "put",
          data: loyaltyData,
          onErrorSubmit: (error) => {
            console.error("Error in useRequest:", error); // Log detailed error information
          },
        });
      }
      if (response) {
        // Update the loyaltyPoints state with the new data
        setLoyaltyPoints((prev) => ({
          ...prev,
          ...loyaltyData,
        }));
        ShowToast(lang("Loyalty points updated successfully"), Severty.SUCCESS);
      }
      console.log("Response:", response);
    } catch (error) {
      console.error("Error updating loyalty points:", error);
      ShowToast(lang("Error updating loyalty points"), Severty.ERROR);
    }
  };


  return (
    <div className="loyalty-point-form-wrap" style={{ padding: "16px" }}>
      <Card>
        <Title level={2}>{lang("Loyalty Points Management")}</Title>
        <LoyaltyForm
          formValues={formValues}
          setFormValues={setFormValues}
          onSubmit={handleLoyaltySubmit}
        />
        {loyaltyPoints && (
          <div style={{ marginTop: "24px" }}>
            <Title level={3}>{lang("Current Loyalty Points Settings")}</Title>
            <Card bordered={true}>
              <p>
                <strong>{lang("Conversion Cash to Points")}:</strong>{" "}
                {loyaltyPoints.conversionRate}
              </p>
              <p>
                <strong>{lang("Conversion Points to Cash")}:</strong>{" "}
                {loyaltyPoints.deliveryConversionRate}
              </p> 
              <p>
                <strong>{lang("Refferal Points for reward")}:</strong>{" "}
                {loyaltyPoints.refer_point}
              </p>
              <p>
                <strong>{lang("Expiry Time")}:</strong>{" "}
                {loyaltyPoints.expiryTime} {`${lang('Months')}`}
              </p> 

            </Card>
          </div>
        )}
      </Card>
    </div>
  );
};

export default LoyaltyPoints;
