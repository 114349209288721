import React, { useEffect, useState } from "react";
import { Button, Form, Input, Skeleton, Card, Row, Col, message } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import DescriptionEditor from "../../components/DescriptionEditor";
import { ShowToast, Severty } from "../../helper/toast";
import lang from "../../helper/langHelper";
import { useAppContext } from "../../context/AppContext";


const FAQEdit = () => {
  const sectionName = lang("FAQ");
  const routeName = `cms?path=${lang("FAQ")}`;

  const { request } = useRequest();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [editorValue, setEditorValue] = useState("");
  const [arDescription, setArDescription] = useState("");
  const { setPageHeading } = useAppContext();
  const heading = lang("FAQ");

  useEffect(() => {
    // setPageHeading(heading);
    if (id) {
      setLoading(true);
      fetchFAQ(id);
    } else {
      form.resetFields(); // Reset form fields if adding a new FAQ
    }
  }, [id]);

  const fetchFAQ = (id) => {
    request({
      url: `/admin/Faq/${id}`,
      method: "GET",
      onSuccess: (data) => {
        console.log("fetchFAQ success:", data);
        setLoading(false);
        form.setFieldsValue(data.data);
        setEditorValue(data.data.answer);
        setArDescription(data.data.ar_answer);
      },
      onError: (error) => {
        console.log("fetchFAQ error:", error);
        setLoading(false);
        message.error("Failed to fetch FAQ");
      },
    });
  };

  const handleUpdate = (values) => {
    console.log("handleUpdate called with values:", values);
    if (editorValue.trim() === "<p></p>" || editorValue.trim() === "")
      return ShowToast("Please Enter Answer", Severty.ERROR);
    if (arDescription.trim() === "<p></p>" || arDescription.trim() === "")
      return ShowToast("Please Enter Arabic Answer", Severty.ERROR);

    const payload = id
      ? {
          question: values.question,
          answer: editorValue,
          ar_question: values.ar_question,
          ar_answer: arDescription,
        }
      : {
          question: [
            {
              question: values.question,
              answer: editorValue,
              ar_question: values.ar_question,
              ar_answer: arDescription,
            },
          ],
        };

    setLoading(true);
    request({
      url: id ? `/admin/Faq/${id}` : "/admin/Faq",
      method: id ? "PUT" : "POST",
      data: payload,
      onSuccess: (data) => {
        console.log("handleUpdate success:", data);
        setLoading(false);
        message.success(`FAQ ${id ? "updated" : "created"} successfully`);
        navigate(`/${routeName}`);
      },
      onError: (error) => {
        console.log("handleUpdate error:", error);
        setLoading(false);
        message.error(`Failed to ${id ? "update" : "create"} FAQ`);
      },
    });
  };

  const handleDelete = () => {
    console.log("handleDelete called with id:", id);
    setLoading(true);
    request({
      url: `/admin/Faq/${id}`,
      method: "DELETE",
      onSuccess: (data) => {
        console.log("handleDelete success:", data);
        setLoading(false);
        message.success("FAQ deleted successfully");
        navigate(`/${routeName}`);
      },
      onError: (error) => {
        console.log("handleDelete error:", error);
        setLoading(false);
        message.error("Failed to delete FAQ");
      },
    });
  };

  return (
    <>
      <Card title={lang(id ? "Update" : "Add") + sectionName}>
        {loading && id ? (
          [1, 2].map((item) => <Skeleton active key={item} />)
        ) : (
          <Form
            className="edit-page-wrap"
            form={form}
            onFinish={handleUpdate}
            autoComplete="off"
            layout="vertical"
            name="faq_form"
          >
            <Row gutter={[24, 0]}>
              <Col span={24} md={12}>
                <Form.Item
                  normalize={(value) => value.trimStart()}
                  label={lang("Question")}
                  name="question"
                  rules={[
                    {
                      required: true,
                      message: lang("Please enter the question"),
                    },
                  ]}
                >
                  <Input autoComplete="off" placeholder={lang("Please enter the question")} />
                </Form.Item>
              </Col>

              <Col span={24} md={12}>
                <Form.Item
                  normalize={(value) => value.trimStart()}
                  label={lang("Arabic Question")}
                  name="ar_question"
                  rules={[
                    {
                      required: true,
                      message: lang("Please enter the Arabic question"),
                    },
                  ]}
                >
                  <Input
                    autoComplete="off"
                    placeholder={lang("Please enter the Arabic question")}
                  />
                </Form.Item>
              </Col>

              <Col span={24} md={12}>
                <Form.Item
                  label={lang("Answer")}
                  name="answer"
                  rules={[
                    {
                      required: true,
                      message: lang("Please enter the answer"),
                    },
                  ]}
                >
                  <DescriptionEditor
                    value={editorValue}
                    placeholder={lang("Please enter the answer")}
                    onChange={(data) => setEditorValue(data)}
                  />
                </Form.Item>
              </Col>

              <Col span={24} md={12}>
                <Form.Item
                  label={lang("Arabic Answer")}
                  name="ar_answer"
                  rules={[
                    {
                      required: true,
                      message: lang("Please enter the Arabic answer"),
                    },
                  ]}
                >
                  <DescriptionEditor
                    value={arDescription}
                    placeholder={lang("Please enter the Arabic answer")}
                    onChange={(data) => setArDescription(data)}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item className="btn-row float-right">
              <Link
                className="ant-btn ant-btn-primary btnStyle btnOutlineDelete"
                to={`/${routeName}`}
              >
                {lang("Back")}
              </Link>
              <Button
                className="primary_btn btnStyle"
                loading={loading}
                htmlType="submit"
              >
                {lang("Submit")}
              </Button>
              {id && (
                <Button
                  type="danger"
                  onClick={handleDelete}
                  style={{ marginLeft: "10px" }}
                  loading={loading}
                >
                  {lang("Delete")}
                </Button>
              )}
            </Form.Item>
          </Form>
        )}
      </Card>
    </>
  );
};

export default FAQEdit;
