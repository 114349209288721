import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";

import { Bar, Line } from "react-chartjs-2";
import React, { useEffect, useState } from "react";

import apiPath from "../constants/apiPath";
import { Severty, ShowToast } from "../helper/toast";
import useRequest from "../hooks/useRequest";
import moment from "moment";
import { Skeleton } from "antd";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  BarElement,
);

export const options = {
  type: "line",
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: "Sales Report",
    },
  },
};

export const DeliveryWeeklyChart = ({ year }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { request } = useRequest();
  const lastSixWeeks = getLastSixWeeks();

  const barChartData = {
    labels: lastSixWeeks.map(({ startDate }) => startDate),
    datasets: [
      {
        label: "Sales",
        data: data,
        backgroundColor: "rgba(52, 122, 226, 0.16)",
        borderColor: "red",
        borderWidth: 1,
        pointStyle: "circle",
        pointRadius: 10,
        pointHoverRadius: 15,
      },
    ],
  };

  const fetchData = () => {
    request({
      url: `${apiPath.report}/delivery-discount?period=weekly${
        year ? `&year=${year}` : ``
      }`,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        console.log(data, "weekly");
        if (status) {
          const arr = [0, 0, 0, 0, 0, 0];
          data.sales.map(({ interval, count }) => {
            const findIndex = lastSixWeeks.findIndex(
              ({ weekNumber }) => weekNumber == parseInt(interval),
            );
            if (findIndex != -1) {
              arr[findIndex] = count;
            } else {
              arr[findIndex] = 0;
            }
          });
          console.log(arr, "llll");
          setData(arr);
        }
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    fetchData();
  }, [year]);

  useEffect(() => {
    console.log(data, "HG");
  }, [data]);

  return (
    <div>
      {loading ? (
        [1, 2].map((item) => <Skeleton active key={item} />)
      ) : (
        <Line className="line-charts" options={options} data={barChartData} />
      )}
    </div>
  );
};

export const PickUpWeeklyChart = ({ year }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { request } = useRequest();
  const lastSixWeeks = getLastSixWeeks();

  const barChartData = {
    labels: lastSixWeeks.map(({ startDate }) => startDate),
    datasets: [
      {
        label: "Sales",
        data: data,
        backgroundColor: "rgba(52, 122, 226, 0.16)",
        borderColor: "red",
        borderWidth: 1,
        pointStyle: "circle",
        pointRadius: 10,
        pointHoverRadius: 15,
      },
    ],
  };

  const fetchData = () => {
    request({
      url: `${apiPath.report}/vendor-discount?period=weekly${
        year ? `&year=${year}` : ``
      }`,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        console.log(data, "weekly");
        if (status) {
          const arr = [0, 0, 0, 0, 0, 0];
          data.sales.map(({ interval, count }) => {
            const findIndex = lastSixWeeks.findIndex(
              ({ weekNumber }) => weekNumber == parseInt(interval),
            );
            if (findIndex != -1) {
              arr[findIndex] = count;
            } else {
              arr[findIndex] = 0;
            }
          });
          console.log(arr, "llll");
          setData(arr);
        }
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    fetchData();
  }, [year]);

  useEffect(() => {
    console.log(data, "HG");
  }, [data]);

  return (
    <div>
      {loading ? (
        [1, 2].map((item) => <Skeleton active key={item} />)
      ) : (
        <Line className="line-charts"  options={options} data={barChartData} />
      )}
    </div>
  );
};

export default DeliveryWeeklyChart;

function getLastSixWeeks1() {
  const lastSixWeeks = [];
  for (let i = 5; i >= 0; i--) {
    lastSixWeeks.push(
      moment().subtract(i, "weeks").startOf("isoWeek").format("YYYY-MM-DD"),
    );
  }
  return lastSixWeeks;
}

function getLastSixWeeks() {
  const lastSixWeeks = [];
  for (let i = 5; i >= 0; i--) {
    const startDate = moment().subtract(i, "weeks").startOf("isoWeek");
    const weekNumber = startDate.isoWeek();
    lastSixWeeks.push({
      startDate: startDate.format("YYYY-MM-DD"),
      weekNumber: weekNumber,
    });
  }
  return lastSixWeeks;
}
