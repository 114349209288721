import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Switch,
  TimePicker,
} from "antd";
import React, { useEffect, useState } from "react";

import {
  PhoneNumberField,
  SelectInput,
  TextInputBox,
} from "../../components/InputField";
import SingleImageUpload from "../../components/SingleImageUpload";
import MultipleImageUpload from "../../components/MultipleImageUpload2";
import apiPath from "../../constants/apiPath";
import { useAppContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import LocationMap from "../User/LocationMap";
import { Palestine } from "../../constants/var";
import { useAuthContext } from "../../context/AuthContext";
import PhoneInput from "react-phone-input-2";
import checkNumber from '../../helper/apis'
const weekdays = [
  { name: "sunday", label: "S" },
  { name: "monday", label: "M" },
  { name: "tuesday", label: "T" },
  { name: "wednesday", label: "W" },
  { name: "thursday", label: "Th" },
  { name: "friday", label: "F" },
  { name: "saturday", label: "ST" },
];

const AddForm = ({ section, show, hide, data, refresh }) => {
  const api = {
    restaurant: apiPath.restaurant,
    country: apiPath.common.countries,
    city: apiPath.common.city,
    fc: apiPath.common.foodCategories,
    rc: apiPath.common.restaurantCategories,
  };

  const [form] = Form.useForm();
  const { request } = useRequest();
  const [file, setFile] = useState([]);
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [restaurantCategories, setRestaurantCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [areas, setAreas] = useState([]);
  const [document, setDocument] = useState();
  // const [isPrimary, setIsPrimary] = useState(false);
  const [logo, setLogo] = useState();
  const { country, cities } = useAppContext();
  const [selectedTime, setSelectedTime] = useState({
    open: null,
    close: null,
  });
  const [mobileNumber, setMobileNumber] = useState(null);
  const [mobileNumber2, setMobileNumber2] = useState({});
  const [deliveryTypes, setDeliveryTypes] = useState([]);
  const { language } = useAppContext();

  const getCategory = (country_id) => {
    request({
      url: api.rc + `?country_id=${country_id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        if (status) {
          console.log("data1", data);

          const ar_category = data.map(item => ({ ...item, name: item.ar_name }));

          setRestaurantCategories(language === "en" ? data : ar_category);

        }
        console.log(data, "data");
      },
      onError: (err) => {
        console.log(err);
      },
    });
  };

  // const handlePrimaryChange = (event) => {
  //   setIsPrimary(event.target.checked)
  // };

  useEffect(() => {
    //  getCountry();
    // getCities();
    // getCategory();
    // getFoodCategory();
  }, []);

  const FileType = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "image/avif",
    "image/webp",
    "image/gif",
  ];

  const getAreas = (id) => {
    request({
      url: `/city-area/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "setCities");
        if (data) {
          // const ar_area = data.map(item => ({ ...item, name: item.ar_name }));
          setAreas(data);
        }
      },
    });
  };
  const handleImage = (value) => {
    setImage((prev) => {
      return value.map((item) => {
        return item.url;
      });
    });
  };

  useEffect(() => {
    if (!country.country_id) return;
    getCategory(country.country_id);
  }, [country.country_id]);

  useEffect(() => {
    if (!data) return;
    console.log(data);


    console.log("data.auto_accept_order", data.auto_accept_order);

    form.setFieldsValue({ ...data });
    setFile([data.image]);
  }, [data]);

  const onCreate = async (values) => {

    setLoading(true);
    // const valid = await checkNumber({ number: mobileNumber.country_code + mobileNumber.mobile_number })
    // if (!valid) return setLoading(false);

    const checks = [
      checkNumber({ number: mobileNumber.country_code + mobileNumber.mobile_number })
    ];
 
    if (mobileNumber2?.mobile_number_sec?.length > 4) {
      checks.push(checkNumber({ number: mobileNumber2.country_code_sec + mobileNumber2.mobile_number_sec }));
    }
  
    const results = await Promise.all(checks);

    if (results.some(valid => !valid)) {
      return setLoading(false);
    }

    console.log(values, "hfjhdkghkhdgkd");
    setLoading(true);
    const { location, time } = values;
    let payload = {
      ...values,
      open_time: time[0],
      close_time: time[1],
      logo: logo,
      country_id: country.country_id,
      ...mobileNumber,
      ...mobileNumber2,
      ...location,
      document: document,
    };

    console.log(payload, "payload");

    request({
      url: `${data ? api.restaurant + "/" + data._id : api.restaurant}`,
      method: data ? "PUT" : "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
          setLoading(false);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const handleUploadLogo = (data) => {
    setLogo(data[0]?.url);
  };

  const handleUploadLegal = (data) => {
    setDocument(data[0]?.url);
  };

  const handleMobileNumberChange = (value, data, type) => {
    let country_code = data?.dialCode;
    setMobileNumber({
      country_code: country_code,
      mobile_number: value.slice(data?.dialCode?.length),
    });
  };
  const handleMobileNumberChange2 = (value, data, type) => {
    let country_code = data?.dialCode;
    setMobileNumber2({
      country_code_sec: country_code,
      mobile_number_sec: value.slice(data?.dialCode?.length),
    });
  };

  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };
  const dummyDeliveryTypes = [
    { name: "Delivery", ar_name: "توصيل", _id: "Delivery" },
    { name: "Pickup", ar_name: "يلتقط", _id: "Pickup" },
  ];

  const handleFailedSubmit = (errorInfo) => {
    console.log("Form submission failed with errors:", errorInfo);
    // Handle form submission errors   
  };

  const handleLocationChange = (value) => { };

  return (
    <Modal
      open={show}
      width={1400}
      okText="Save"
      onCancel={hide}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
      footer={null}
      className="tab_modal"
    >
      <Form
        initialValues={{
          have_whatsapp_sec: false, // handleDeliveryTypeChange(value);
          have_whatsapp: false,
          auto_accept_order: true,
          country_id: country.country_id,
          tax: 10,
          commission_rate: 20,
          online_commission_rate: 23,
        }}
        id="create"
        form={form}
        onFinish={onCreate}
        layout="vertical"
      >
        <h4 className="modal_title_cls">{`${data ? lang("Update") + " " + section : lang("Add") + " " + section
          }`}</h4>
        <Row gutter={[16, 16]}>
          <Col span={24} lg={14} xs={24} className="d-flex flex-column gap-3">
            <Card className="">
              <div className="row g-3">
                <SelectInput
                  className="w-100"
                  mode="multiple"
                  name="category_id"
                  label={lang("Restaurant Category")}
                  placeholder={lang("Select Category")}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={restaurantCategories}
                  rules={[
                    { required: true, message: lang("Missing Type Selection") },
                  ]}
                />

                <div className="col-md-6">
                  <Form.Item
                    className="mb-0"
                    rules={[
                      {
                        validator: (_, value) => {
                          if (value !== undefined && value?.length > 0) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(lang("Logo is required")),
                          );
                        },
                      },
                    ]}
                    label={lang("Upload Logo")}
                    name="logo1"
                    placeholder={lang("Upload Logo")}
                  >
                    <SingleImageUpload
                      value={logo}
                      fileType={FileType}
                      imageType={"logo"}
                      onChange={(data) => handleUploadLogo(data)}
                    />
                  </Form.Item>
                  <p
                    className="img-size-details"
                    style={{ fontSize: 11, marginTop: 4 }}
                  >
                    {lang(`**Logo should be 600x400 for best view in gallery image. You
                    can select only (.png, .jpeg, .jpg) format files upto 1 MB
                    file size. .!!!`)}
                  </p>
                </div>
                {/* <Col span={24} lg={8} xs={24} className="mb-2" > */}
                <div className="col-md-6">
                  <Card className="" style={{ height: "100%" }}>
                    <div className="row g-3">
                      <Col span={24}>
                        <Form.Item
                          className="upload_wrap img-uploaded"
                          rules={[
                            {
                              validator: (_, value) => {
                                if (value !== undefined && value?.length > 0) {
                                  return Promise.resolve();
                                }
                                // return Promise.reject(
                                //   new Error("Image is required"),
                                // );
                              },
                            },
                          ]}
                          label={lang("Upload Image")}
                          name="image"
                          getValueFromEvent={(event) => {
                            return event.map((item) => item.thumbUrl).join(",");
                          }}
                        >
                          <MultipleImageUpload
                            // disabled={!isEdit}
                            value={image}
                            data={image}
                            fileType={FileType}
                            imageType={"category"}
                            btnName={"Image"}
                            isDimension={true}
                            onChange={(data) => handleImage(data)}
                          />
                        </Form.Item>
                      </Col>
                    </div>
                  </Card>
                </div>

                {/* </Col> */}
              </div>
            </Card>

            <Card className="">
              <div className="row g-3">
                <TextInputBox
                  name="name"
                  label={lang("Restaurant’s Name")}
                  placeholder={lang("Enter Restaurant Name")}
                  rules={[
                    {
                      max: 250,
                      message: lang(
                        "Name should contain more then 250 characters!",
                      ),
                    },
                    {
                      required: true,
                      message: lang("Please Enter Restaurant Name"),
                    },
                  ]}
                />

                <TextInputBox
                  name="ar_name"
                  label={lang("Restaurant’s Name Arabic")}
                  placeholder={lang("Enter Restaurant Name")}
                  rules={[
                    {
                      max: 250,
                      message: lang(
                        "Name should contain more then 250 characters!",
                      ),
                    },
                    {
                      required: true,
                      message: lang("Please Enter Restaurant Name"),
                    },
                  ]}
                />
                <TextInputBox
                  name="description"
                  label={lang("Restaurant Description")}
                  placeholder={lang("Enter Restaurant Description")}
                  rules={[
                    {
                      max: 500,
                      message: lang(
                        "Description should contain more then 500 characters!",
                      ),
                    },
                    {
                      required: true,
                      message: lang("Please Enter Restaurant Description"),
                    },
                  ]}
                />
                <TextInputBox
                  name="ar_description"
                  label={lang("Restaurant Description Arabic")}
                  placeholder={lang("Enter Restaurant Description")}
                  rules={[
                    {
                      max: 500,
                      message: lang(
                        "Description should contain more then 500 characters!",
                      ),
                    },
                    {
                      required: true,
                      message: lang("Please Enter Restaurant Description"),
                    },
                  ]}
                />
                <div className="col-xxl-6 col-lg-12 col-md-6">
                  <Form.Item
                    className="checkBox_wrap"
                    label={lang("Working Days")}
                    name="working_days"
                  >
                    <Checkbox.Group
                      onChange={(value) => console.log(value, "working")}
                    >
                      {weekdays.map((item, idx) => (
                        <Checkbox value={item.name} key={idx}>
                          {item.label}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>
                  </Form.Item>
                </div>
                <div className="col-xxl-6 col-lg-12 col-md-6 px-0">
                  <Row gutter={20}>
                    <Col span={24} md={24}>
                      <Form.Item
                        label={lang("Working Time")}
                        name="time"
                        rules={[
                          {
                            required: true,
                            message: lang("Please select working time"),
                          },
                        ]}
                      >
                        <TimePicker.RangePicker
                          format="h:mm a"
                          placeholder={[lang("Open Time"), lang("Close Time")]}
                          use12Hours
                          order={false}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>
              </div>
            </Card>
            <Card>
              <Row gutter={[16, 16]} className="g-3">
                <SelectInput
                  name="city_id"
                  label={lang("City")}
                  placeholder={lang("Select City")}
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={cities}
                  cover={{ md: 8 }}
                  colProps={{ sm: 24, span: 24 }}
                  rules={[
                    { required: true, message: lang("Missing Type Selection") },
                  ]}
                  handleChange={(value) => {
                    getAreas(value);
                    form.setFieldsValue({ area: null });
                  }}
                />

                {country.country_id == Palestine.id && (
                  <SelectInput
                    name="area"
                    label={lang("Area")}
                    placeholder={lang("Select Area")}
                    showSearch
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    options={areas} //TODO:  change to area
                    cover={{ md: 12 }}
                    colProps={{ sm: 24, span: 24 }}
                    rules={[
                      {
                        required: country.country_id == Palestine.id,
                        message: lang("Missing Type Selection"),
                      },
                    ]}
                  />
                )}

                <TextInputBox
                  name="address"
                  label={lang("Address")}
                  placeholder={lang("Enter Address")}
                  colProps={{ sm: 24, span: 24 }}
                  rules={[
                    {
                      max: 600,
                      message: lang(
                        "Name should contain more then 600 characters!",
                      ),
                    },
                    {
                      required: true,
                      message: lang("Please Enter Address"),
                    },
                  ]}
                />
                <TextInputBox
                  name="ar_address"
                  label={lang("Address Arabic")}
                  placeholder={lang("Enter Address")}
                  colProps={{ sm: 24, span: 24 }}
                  rules={[
                    {
                      max: 600,
                      message: lang(
                        "Name should contain more then 600 characters!",
                      ),
                    },
                    {
                      required: true,
                      message: lang("Please Enter Address"),
                    },
                  ]}
                />
              </Row>
            </Card>

            <Row gutter={20}>
              <Col md={12}>
                <Card>
                  <Row gutter={[0, 16]} className="g-3">
                    <TextInputBox
                      name="contact_person_name"
                      label={lang("Contact Person Name")}
                      placeholder={lang("Enter Contact Person Name")}
                      cover={{ md: 24 }}
                      colProps={{ sm: 24, span: 24 }}
                      rules={[
                        {
                          max: 20,
                          message: lang(
                            "Name should contain more then 20 characters!",
                          ),
                        },
                        {
                          required: true,
                          message: lang("Please Enter Person Name"),
                        },
                      ]}
                    />

                    <Col span={24} sm={24}>
                      <Form.Item
                        label={lang("Email ID")}
                        name="email"
                        rules={[
                          {
                            type: "email",
                            message: lang(
                              "Please enter a valid email address!",
                            ),
                          },
                          {
                            max: 50,
                            message: lang(
                              "Email address not more then 255 characters!",
                            ),
                          },
                          {
                            required: true,
                            message: lang("Please enter email!"),
                          },
                        ]}
                      >
                        <Input placeholder={lang("Enter Email ID")} />
                      </Form.Item>
                    </Col>


                    {/*                       
                    <PhoneNumberField
                        label={lang("Contact Person Phone Number")}
                        name="mobile"
                        placeholder={lang("Enter Phone Number")}
                        cover={{ md: 24 }}
                        colProps={{ sm: 24, span: 24 }}
                        className=""
                        rules={[
                          {
                            required: true,
                            validator: (rule, value) => {
                              console.log(value)
                              if (!value) {
                                return Promise.reject(lang("Please enter phone number"));
                              }
                              if(mobileNumber.country_code === "972" || mobileNumber.country_code === "970"){
                                if (mobileNumber.mobile_number && !/^5/.test(mobileNumber.mobile_number))  return Promise.reject(lang("Number must start with digit 5."));
                                if (mobileNumber.mobile_number && !/^972\d{9}$/.test(value))  return Promise.reject(lang("Number must be 9 digits."));
                              }
                              // if (!/^\d{8,15}$/.test(value)) {
                              //   return Promise.reject(
                              //     lang("Phone number must be between 8 and 12 digits"),
                              //   );
                              // }
                              return Promise.resolve();
                            },
                          },
                        ]}
                        onChange={handleMobileNumberChange}
                        countryCodeEditable={false} // Prevents editing the country code
                      /> */}

                    <Col span={24} lg={24} sm={24} className="flagMobileNumber">
                      <Form.Item
                        label={lang("Mobile Number")}
                        name="mobile"
                        form={form}
                        rules={[
                          {
                            required: true,
                            message: lang("Please enter phone number")
                          },
                          {
                            validator: () => {
                              console.log(mobileNumber, "mobileNumber");
                              const number = mobileNumber.mobile_number
                              if (mobileNumber.country_code === "972") {
                                if (number && !/^5/.test(number)) return Promise.reject(lang("Number must start with number 5."));
                                if (number && !/^5\d{8}$/.test(mobileNumber.mobile_number)) return Promise.reject(lang("Number must be 9 digits."));
                              }
                              if (mobileNumber.country_code === "970") {
                                if (number && !/^5/.test(number)) return Promise.reject(lang("Number must start with number 5."));
                                if (number && !/^5\d{8}$/.test(number)) return Promise.reject(lang("Number must be 9 digits"));
                              }

                              if (!/^\d+$/.test(number)) {
                                return "Invalid value: " + number;
                              }

                              return Promise.resolve()
                            }
                          }
                        ]}
                      >
                        <PhoneInput
                          inputProps={{
                            name: "mobile",
                            required: true,
                            autoFocus: false,
                            autoFormat: false,
                            autoComplete: "off",
                          }}
                          country={"il"}
                          preferredCountries={["ps", "il"]}

                          onChange={handleMobileNumberChange}
                          countryCodeEditable={false} // Prevents editing the country code
                          specialLabel="" // Optional: Removes the default label
                        />
                      </Form.Item>
                    </Col>
                    <Form.Item name="have_whatsapp" valuePropName="checked">
                      <Checkbox className="" onChange={onChange}>
                        {lang("This number have WhatsApp to receive messages?")}{" "}
                      </Checkbox>
                    </Form.Item>


                    <Col span={24} lg={24} sm={24} className="flagMobileNumber">
                      <Form.Item
                        label={lang("Mobile Number")}
                        name="mobile2"
                        form={form}
                        rules={[
                          {
                            validator: () => {
                              console.log(mobileNumber2, "mobileNumber2");
                              if (!mobileNumber2?.mobile_number_sec || mobileNumber2?.mobile_number_sec.length === 0) return Promise.resolve()
                              const number = mobileNumber2.mobile_number_sec
                              if (mobileNumber2.country_code === "972") {
                                if (number && !/^5/.test(number)) return Promise.reject(lang("Number must start with number 5."));
                                if (number && !/^5\d{8}$/.test(mobileNumber2.mobile_number)) return Promise.reject(lang("Number must be 9 digits."));
                              }
                              if (mobileNumber2.country_code === "970") {
                                if (number && !/^5/.test(number)) return Promise.reject(lang("Number must start with number 5."));
                                if (number && !/^5\d{8}$/.test(number)) return Promise.reject(lang("Number must be 9 digits"));
                              }

                              if (!/^\d+$/.test(number)) {
                                return "Invalid value: " + number;
                              }

                              return Promise.resolve()
                            }
                          }
                        ]}
                      >
                        <PhoneInput
                          inputProps={{
                            name: "mobile2",
                            required: true,
                            autoFocus: false,
                            autoFormat: false,
                            autoComplete: "off",
                          }}
                          // isValid={(value, country) => {
                          //   const countryCode = country.iso2;
                          //   const number = value.slice(country?.dialCode?.length)
                          //     if (countryCode === 'il') {
                          //       if (number && !/^5/.test(number))  return lang("Number must start with number 5.");
                          //       if (number && !/^972\d{9}$/.test(value))  return lang("Number must be 9 digits.");
                          //     }
                          //     if (countryCode === 'ps') {
                          //       if (number && !/^5/.test(number))  return lang("Number must start with number 5.");
                          //       if (number && !/^970\d{9}$/.test(value)) return lang("Number must be 9 digits");
                          //     }

                          //     if (!/^\d+$/.test(value)) {
                          //       return "Invalid value: " + value;
                          //     }
                          //     return true;
                          // }}
                          country={"il"}
                          preferredCountries={["ps", "il"]}
                          // value={
                          //   mobileNumber
                          //     ? (mobileNumber.country_code
                          //         ? mobileNumber.country_code
                          //         : "+27") +
                          //       (mobileNumber.mobile_number ? mobileNumber.mobile_number : null)
                          //     : "+27"
                          // }
                          onChange={handleMobileNumberChange2}
                          countryCodeEditable={false} // Prevents editing the country code
                          specialLabel="" // Optional: Removes the default label
                        />
                      </Form.Item>
                    </Col>

                    {/* 
                    <PhoneNumberField
                      label={lang("Contact Person Phone Number")}
                      name="mobile2"
                      placeholder={lang("Enter Phone Number")}
                      cover={{ md: 24 }}
                      colProps={{ sm: 24, span: 24 }}
                      className=""
                      onChange={handleMobileNumberChange2}
                      countryCodeEditable={false}
                      rules={false}
                    /> */}
                    <Form.Item name="have_whatsapp_sec" valuePropName="checked">
                      <Checkbox className="" onChange={onChange}>
                        {lang("This number have WhatsApp to receive messages?")}{" "}
                      </Checkbox>
                    </Form.Item>
                  </Row>
                </Card>
              </Col>
              <Col md={12}>
                <Card>
                  <Row gutter={[16, 16]} className="g-3">
                    <Col span={24} md={24}>
                      <Form.Item
                        label={lang("Create Password")}
                        name="password"
                        rules={[
                          {
                            pattern: new RegExp(
                              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*]).{8,}$/,
                            ),
                            message: lang(
                              "Confirm password atleast contain 8 characters, atleast contain one captital letter, atleast contain one small letter, atleast contain one digit, atleast contain one special character",
                            ),
                          },
                          {
                            required: true,
                            message: lang("Please enter your password!"),
                          },
                        ]}
                      >
                        <Input.Password
                          onCut={(e) => e.preventDefault()}
                          onCopy={(e) => e.preventDefault()}
                          onPaste={(e) => e.preventDefault()}
                          autoComplete="off"
                          placeholder={lang("Create Password")}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24} md={24}>
                      <Form.Item
                        label={lang("Confirm Password")}
                        name="confirm_password"
                        dependencies={["password"]}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: lang("Enter the confirm password!"),
                          },

                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("password") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  lang(
                                    "Password that you entered doesn't match!",
                                  ),
                                ),
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          placeholder={lang("Enter Confirm Password")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col span={24} lg={10} xs={24}>
            <Card>
              <Row gutter={[16, 16]}>
                <Col md={12} sm={24} span={24}>
                  <Form.Item
                    mode="multiple"
                    name="delivery_type"
                    label={lang("Delivery Type")}
                    rules={[
                      {
                        required: true,
                        message: lang("Missing Type Selection"),
                      },
                    ]}
                  >
                    <Select
                      placeholder={lang("Select Delivery Type")}
                      className={"mb-0"}
                      onChange={(value) => {
                        setDeliveryTypes(value);
                      }}
                      autoComplete="off"
                      mode="multiple"
                    >
                      {dummyDeliveryTypes.map((item) => (
                        <Select.Option
                          key={item._id}
                          value={item._id}
                          label={language === "en" ? item.name : item.ar_name}
                        >
                          <span className="cap">{language === "en" ? item.name : item.ar_name}</span>
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24} md={12}>
                  <Form.Item
                    name="min_order_price"
                    label={lang("Min. Order Price")}
                    placeholder={lang("Enter Min. Order Price")}
                    cover={{ md: 10 }}
                    rules={[
                      {
                        required: true,
                        message: lang("Missing Min. Order Price"),
                      },
                    ]}
                  >
                    <InputNumber placeholder={lang("Enter Min. Order Price")} />
                  </Form.Item>
                </Col>

                {/* THIS IS ACTUALLY FOOD PREPARATION TIME WHICH WILL BE SHOWN TO THE CUSTOMER */}
                {/* {deliveryTypes.includes(dummyDeliveryType[0].name) && ( */}
                <Col span={24} md={24}>
                  <Form.Item
                    name="approx_delivery_time"
                    label={lang("Approximate preparation time(HH:MM)")}
                    rules={[
                      {
                        // required: deliveryTypes.includes(
                        //   dummyDeliveryType[0].name,
                        // ),
                        required: true,
                        message: lang("Missing Approximate preparation time"),
                      },
                      {
                        validator: (_, value) => {
                          if (/^\d{2}:\d{2}$/.test(value) || value === "") {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              lang("Enter preparation time in HH:MM format"),
                            ),
                          );
                        },
                      },
                    ]}
                  >
                    <Input placeholder="01:30" />
                  </Form.Item>
                </Col>
                {/* )} */}

                <Col span={24} md={12}>
                  <Form.Item
                    name="tax"
                    label={lang("Store Tax %")}
                    rules={[
                      {
                        required: true,
                        message: lang("Please Enter Store Tax Percentage"),
                      },
                    ]}
                  >
                    <InputNumber
                      formatter={(value) => `${value}%`}
                      parser={(value) => value.replace("%", "")}
                      min={0}
                      max={100}
                      placeholder={lang("Enter Tax %")}
                    />
                  </Form.Item>
                </Col>

                <Col span={24} md={12}>
                  <Form.Item
                    name="commission_rate"
                    label={lang("Cash Commission Rate %")}
                    rules={[
                      {
                        required: true,
                        message: lang("Please Enter Cash Commission Rate"),
                      },
                    ]}
                  >
                    <InputNumber
                      formatter={(value) => `${value}%`}
                      parser={(value) => value.replace("%", "")}
                      max={100}
                      placeholder="10 %"
                      min={0}
                    />
                  </Form.Item>
                </Col>

                <Col span={24} md={12}>
                  <Form.Item
                    name="online_commission_rate"
                    label={lang("Online Commission Rate %")}
                    rules={[
                      {
                        required: true,
                        message: lang("Please Enter Online Commission Rate"),
                      },
                    ]}
                  >
                    <InputNumber
                      formatter={(value) => `${value}%`}
                      parser={(value) => value.replace("%", "")}
                      max={100}
                      placeholder="10 %"
                      min={0}
                    />
                  </Form.Item>
                </Col>

              </Row>
              <Row className="mt-3">
                <Col span={24} className="mt-3">
                  <Form.Item
                    valuePropName="checked"
                    label={lang("Auto Accept Orders")}
                    name="auto_accept_order"
                    className="auto_accept_order"
                  >
                    <Switch />
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            <Row className="">
              <Col md={24} className="mt-5">
                <Form.Item
                  label={lang("Location (Drag Marker for Selecting Location)")}
                  name="location"
                  rules={[
                    {
                      required: true,
                      message: lang("Please select the location!"),
                    },
                  ]}
                >
                  <LocationMap
                    className="mt-3"
                    onChange={handleLocationChange}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Card className="mt-4">
              <Row gutter={[0, 16]}>
                <Col span={24} md={24}>
                  <Form.Item
                    name="business_id"
                    label={lang("Business Identification Number")}
                    rules={[
                      {
                        required: true,
                        message: lang("Enter Business Identification Number"),
                      },
                    ]}
                  >
                    <Input
                      placeholder={lang("Enter Business Identification Number")}
                      maxLength={16}
                    />
                  </Form.Item>
                </Col>

                <div className="col-12">
                  <Form.Item
                    className="mb-0"
                    rules={[
                      {
                        validator: (_, value) => {
                          if (value !== undefined && value?.length > 0) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(lang("legal documents is required")),
                          );
                        },
                      },
                    ]}
                    label={lang("Upload legal documents")}
                    name="document1"
                    placeholder="Upload Logo"
                  >
                    <SingleImageUpload
                      value={document}
                      fileType={[
                        ...FileType,
                        "application/pdf",
                        "application/msword",
                      ]}
                      imageType={"legal"}
                      onChange={(data) => handleUploadLegal(data)}
                    />
                  </Form.Item>
                </div>
                {/* <SelectInput
                  name="min_order_price"
                  label="Min. Order Price"
                  placeholder="Select Min. Order Price"
                  options={[
                    { name: "100", _id: "100" },
                    { name: "200", _id: "200" },
                    { name: "300", _id: "300" },
                    { name: "500", _id: "500" },
                    { name: "1000", _id: "1000" },
                  ]}
                  cover={{ md: 24 }}
                  rules={[
                    { required: true, message: "Missing Min. Order Price" },
                  ]}
                /> */}
              </Row>
            </Card>

            <div className="d-flex align-items-center gap-3 justify-content-end mt-5">
              <Button onClick={hide} className=" btnStyle btnOutlineDelete ">
                {lang("Cancel")}
              </Button>
              <Button loading={loading} className="primary_btn btnStyle " htmlType="submit">
                {lang("SUBMIT")}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddForm;
