import {
  Button,
  Card,
  Col,
  Input,
  Modal,
  Row,
  Table,
  Tabs,
  Tooltip,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import Lottie from "react-lottie";
import * as success from "../../assets/animation/success.json";
import apiPath from "../../constants/apiPath";
import { AppStateContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import PaymentApproval from "./_PaymentApproval";
import RestaurantPayment from "./_RestaurantPayment";
import RestaurantPending from "./_ResturantPending";
import DriverPaymentApproval from "./_DriverPaymentApproval";
import DriverWithdrawal from "./_DriverWithdrawal";
import DriverPayment from "./_DriverPayment";
import ResturantDepositPayment from "./_ResturantDepositPayment";
import RestaurantWithdrawal from "./_RestaurantWithdrawal";

const Search = Input.Search;
const { TabPane } = Tabs;

export const QuoteStatus = {
  REQUEST: "request",
  RECEIVED: "received",
  COMPLETE: "complete",
  FULLFILL: "fulfill",
  ADDONS: "addons",
  ITEMDEALS: "itemdeals",
};

function Finance() {
  const { setPageHeading } = useContext(AppStateContext);
  const [selectedTab, setSelectedTab] = useState("all");
  const [selectedTab2, setSelectedTab2] = useState("ApprovalPayment");
  useEffect(() => {
    setPageHeading(lang("Financial Management"));
  }, []);

  const handleTabChange = (status) => {
    setSelectedTab(status);
  };

  const handleTabChange2 = (status) => {
    setSelectedTab2(status);
  };

  return (
    <>
      <div className="tabled quoteManagement">
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24}>
            <Card bordered={false} className="criclebox tablespace mb-24">
              <Tabs
                className="main_tabs"
                onTabClick={handleTabChange}
                activeKey={selectedTab}
                tabBarStyle={{ color: "green" }}
              >
                <TabPane tab={lang("Restaurant Pending payment")} key="all">
                  <RestaurantPending />
                </TabPane>

                <TabPane
                  tab={lang("Restaurant Payment Approval")}
                  key={QuoteStatus.REQUEST}
                >
                  <PaymentApproval />
                </TabPane>

                <TabPane
                  tab={lang("Restaurant Payment")}
                  key={"RestaurantPayment"}
                >
                  <RestaurantPayment />
                </TabPane>

                <TabPane
                  tab={lang("Restaurant Deposit Payment Approval")}
                  key={"RestaurantDepositPayment"}
                >
                  <ResturantDepositPayment />
                </TabPane>
                <TabPane
                  tab={lang("Restaurant Withdraw Payment Approval")}
                  key={"RestaurantWithdrawPayment"}
                >
                  <RestaurantWithdrawal />
                </TabPane>
              </Tabs>
            </Card>

            <Card bordered={false} className="criclebox tablespace mb-24">
              <Tabs
                className="main_tabs"
                onTabClick={handleTabChange2}
                activeKey={selectedTab2}
                tabBarStyle={{ color: "green" }}
              >
                <TabPane
                  tab={lang("Driver Payment Approval")}
                  key={"ApprovalPayment"}
                >
                  <DriverPaymentApproval />
                </TabPane>

                <TabPane
                  tab={lang("Drivers Withdraw Approval")}
                  key={`DriversWithdrawApproval`}
                >
                  <DriverWithdrawal />
                </TabPane>

                {/* <TabPane
                  tab={lang("Drivers payment history")}
                  key={`DriversPaymentHistory`}
                >
                  <DriverPayment />
                </TabPane> */}
              </Tabs>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Finance;
