import {
  Button,
  Col,
  Modal,
  Rate,
  Row
} from "antd";
import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Currency from "../../components/Currency";
import { useAppContext } from "../../context/AppContext";
import { dateString, formatPhone } from "../../helper/functions";
import lang from "../../helper/langHelper";

const ViewDetailModal = ({ show, hide, data }) => {

  const [order, setOrder] = useState();
  const [deliveryStatus, setDeliveryStatus] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [orderedItems, setOrderedItems] = useState([]);

  const { language } = useAppContext()

  const DriverOrderStatus = {
    CANCEL: "cancelled",
    PENDING: "pending",
    ACCEPT: "confirmed",
    ARRIVED: "arrived at restaurant",
    PICKUP: "picked up",
    DROP: "arrived at drop location",
    OUT: "out for delivery",
    DELIVERED: "delivered",
  };

  const OrderStatus = {
    ACCEPT: "accepted",
    PENDING: "pending",
    PROCESSING: "processing",
    READY: "ready to pickup",
    PICKUP: "picked up",
    CANCEL: "cancelled",
    DELIVERED: "delivered",
  };

  const RestOrderStatus = {
    PENDING: "pending",
    PROCESSING: "processing",
    READY: "ready to pickup",
    PICKUP: "picked up",
    CANCEL: "cancelled",
    DELIVERED: "delivered",
  };
  const Order = {
    scheduled: "Scheduled",
    "scheduled confirmed": "Scheduled confirmed",
    accepted: "Preparing Order",
    pending: "New Order",
    processing: "processing",
    "ready to pickup": "Ready for Pick Up",
    "picked up": "picked up",
    "out for delivery": "out for delivery",
    cancelled: "cancelled",
    delivered: "delivered",
  };

  const driverStatus = (order) => {
    let status = "Driver not assigned";
    if (
      order?.driver_status === DriverOrderStatus.ACCEPT ||
      order?.driver_status == "accepted"
    ) {
      status = "Driver assigned";
    } else if (order?.driver_status === DriverOrderStatus.ARRIVED) {
      status = "Driver at restaurant";
    } else if (order?.driver_status === DriverOrderStatus.DROP) {
      status = "Arrived at customer";
    } else if (order?.driver_status === DriverOrderStatus.PICKUP) {
      status = "Order Picked Up";
    } else if (order?.driver_status === DriverOrderStatus.DELIVERED) {
      status = "Delivered";
    } else if (order?.driver_status === DriverOrderStatus.CANCEL) {
      if (order?.cancelled_by == "Vendor") status = "Rejected by Restaurant";
      else status = "Cancelled";
    } else {
      // status = order?.driver_status;
      if (order?.status == "processing") status = "Looking for driver";
      if (order?.status == "cancelled") {
        status = "Cancelled";
      }
      if (order?.status == "cancelled" && order?.cancelled_by == "Vendor") {
        status = "Rejected by Restaurant";
      }
    }
    setDeliveryStatus(lang(status));
  };

  const orderstatus = (order) => {
    let text;

    text = Order[order?.status];
    if (order?.status === OrderStatus.ACCEPT) {
      if (order?.restaurant_status === RestOrderStatus.PROCESSING) {
        text = "Preparing Order";
      } else {
        text = "New Order";
      }
    } else if (order?.status === OrderStatus.PROCESSING) {
      text = "Preparing";
    } else if (order?.status === OrderStatus.READY) {
      text = "Ready to pickup";
    } else if (order?.status === OrderStatus.PICKUP) {
      text = "Picked up by driver";
    } else if (order?.status === OrderStatus.DELIVERED) {
    } else if (order?.status === OrderStatus.CANCEL) {
      text = "Rejected";
    } else {
      if (order?.driver_status == "arrived at drop location")
        text = "Driver arrived at customer";
    }

    setOrderStatus(lang(text));
  };


  useEffect(() => {
    if (!data) return;
    setOrder(data);
    driverStatus(data);
    orderstatus(data);
    setOrderedItems(data?.items)
  }, [data]);

  return (
    <>
      <Modal
        open={show}
        width={950}
        okText={lang("Add")}
        onCancel={hide}
        cancelText={null}
        footer={[
          <Button key="okay" type="primary" onClick={hide}>
            {lang("Okay")}
          </Button>,
        ]}
        //   footer={[
        //     <>
        //       <Button key="cancel" type="primary" onClick={hide}>
        //         {lang("Back")}
        //       </Button>
        //     </>,
        //   ]}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="tab_modal edit_orders"
      >
        <h4 className="modal_title_cls">{lang("Order Details")}</h4>
        <div className="order-head">
          <h4>
          {language !== "en" && language !== null
              ? order?.restaurant_id?.[`${language}_name`] ??
              order?.restaurant_id?.name
              : order?.restaurant_id?.name}

          </h4>
          <span>
            <Rate
              disabled
              allowHalf
              value={
                order?.restaurant_id?.rating ? order?.restaurant_id?.rating : 0
              }
            />
            <span className="no-rating">
              {order?.restaurant_id?.rating
                ? parseFloat(order.restaurant_id.rating).toFixed(1)
                : 0}
            </span>
            {" (" +
              (order?.restaurant_id?.review_count
                ? order?.restaurant_id.review_count
                : 0) +
              `(${lang("Reviews")}))`}
          </span>
          <p>
            {lang("Order ID")}: {order?.uid}
          </p>
        </div>
        <Row gutter={[45, 0]}>
          <Col span={24} sm={24} lg={12}>
            <div className="order-dtl-card">
              <div className="order-header">
                <h3>{lang("Order Details")}</h3>
                <p>{dateString(order?.created_at, "lll")}</p>
              </div>

              <div className="order-dtl-list edit-order">
                {orderedItems?.map((item, idx) => (
                  <OrderItem
                    item={item}
                    order={order}
                    key={idx}
                    OrderStatus={OrderStatus}
                    refresh={() => setRefresh((prev) => !prev)}
                  />
                ))}
              </div>
            </div>

            {order?.customer_id && (
              <div className="order-dtl-card">
                <div className="order-header">
                  <h3>{lang("Customer Details")}</h3>
                </div>
                <div className="customer-dtl">
                  <div className="customer-info">
                    <h6>{lang("Name")} :</h6>
                    <h5>{order?.customer_id?.name}
                    </h5>
                  </div>
                  <div className="customer-info">
                    <h6>{lang("Phone Number ")} :</h6>
                    <h5>
                      <Link
                        className="social-media-sec"
                        to={`https://wa.me/${data?.customer_id?.country_code + data?.customer_id?.mobile_number}`}
                        target="_blank"
                      >
                        {formatPhone(
                          order?.customer_id?.country_code,
                          order?.customer_id?.mobile_number,
                        )}
                      </Link>
                    </h5>
                  </div>

                  {order?.is_company_order &&
                    <div className="customer-info">
                      <h6>{lang("Company")} :</h6>
                      <h5>
                        {
                          language !== "en" && language !== null ? order?.company_id?.[`${language}_name`]
                            : order?.company_id?.[`name`]}
                      </h5>
                    </div>
                  }
                  {order?.is_company_order &&
                    <div className="customer-info">
                      <h6>{lang("Company Code")} :</h6>
                      <h5>
                        {order?.company_id?.code}
                      </h5>
                    </div>
                  }

                  <div className="customer-info">
                    <h6>{lang("Address")} :</h6>

                    <h5>{order?.address?.address}, {order?.address?.name}, {order?.address?.landmark}</h5>
                  </div>

                  {order?.address?.area_id && (
                    <div className="customer-info">
                      <h6>{lang("Area")} :</h6>
                      <h5>
                        {order?.address &&
                          language !== "en" &&
                          language !== null
                          ? order?.address?.area_id?.[`${language}_name`]
                          : order?.address?.area_id?.[`name`]}
                      </h5>
                    </div>
                  )}
                  {order?.address?.city_id && (
                    <div className="customer-info">
                      <h6>{lang("City")} :</h6>
                      <h5>
                        {order?.address &&
                          language !== "en" &&
                          language !== null
                          ? order?.address?.city_id?.[`${language}_name`]
                          : order?.address?.city_id?.[`name`]}
                      </h5>
                    </div>
                  )}

                  {order?.is_company_order &&
                    <div className="customer-info">
                      <h6>{lang("Break Slot")} :</h6>
                      <h5>
                        {dateString(order.break_slot_time)}
                      </h5>
                    </div>
                  }
                  {order?.is_company_order &&
                    <div className="customer-info">
                      <h6>{lang("Box Number")} :</h6>
                      <h5>
                        {(order.box_number)}
                      </h5>
                    </div>
                  }
                  {order?.rating?.customer ? (
                    <div className="customer-info">
                      <h6>{lang("Rating")} :</h6>
                      <h5>
                        {order?.rating?.customer}
                      </h5>
                    </div>
                  ) : null}


                </div>
              </div>
            )}

            <div className="order-dtl-card">
              <div className="order-header">
                <h3>{lang("Bill Summary")}</h3>
              </div>
              <div className="customer-dtl">
                <div className="bill-info">
                  <h6>{lang("Order Total")}</h6>
                  <h5>
                    <Currency price={order?.total_amount} />
                  </h5>
                </div>

                {order?.discount > 0 && (
                  <div className="bill-info">
                    <h6>{lang("Discount")}
                      ({`${order?.coupon?.code}`}):
                    </h6>
                    <h5>
                      - <Currency price={order?.discount ?? 0} />
                    </h5>
                  </div>
                )}

                {
                  <div className="bill-info">
                    <h6>
                      {lang("Platform Commission - Restaurant")}
                      {`(${order?.platform_commission_rates?.restaurant ??
                        order?.restaurant_id?.commission_rate
                        }%)`}
                    </h6>
                    <h5>
                      <Currency
                        price={order?.platform_commission?.restaurant ?? 0}
                      />
                    </h5>
                  </div>
                }

                <div className="bill-info">
                  <h6>
                    {lang("Tax")}
                    {`(${order?.restaurant_id?.tax}%)`}
                  </h6>
                  <h5>
                    <Currency price={order?.tax} />
                  </h5>
                </div>

                <div className="bill-info">
                  <h6>{lang("Total (For Restaurant)")}</h6>
                  <h5>
                    <Currency
                      price={((order?.total_amount) - (order?.discount) - (order?.platform_commission?.restaurant ?? 0) + (order?.tax ?? 0) - (order?.is_vendor_pay_delivery ? order.delivery_discount : 0))}
                    />
                  </h5>
                </div>

                {order?.type !== "Pickup" && (
                  <div className="bill-info">
                    <h6>{lang("Delivery Fee")} :</h6>
                    <h5>
                      <Currency price={order?.delivery_fee} />
                    </h5>
                  </div>
                )}
                {
                  order?.is_vendor_pay_delivery && order?.is_free_delivery && (
                    <div className="bill-info">
                      <h6>
                        {lang("Delivery Discount")} (
                        {lang(`By Restaurant`)}):
                      </h6>
                      <h5>
                        <Currency price={-order?.delivery_discount} />
                      </h5>
                    </div>
                  )
                }
                {(order?.is_free_delivery && order?.delivery_coupon) && (
                  <div className="bill-info">
                    <h6>
                      {lang("Delivery Discount")} (
                      {`${order?.delivery_coupon?.code}`}):
                    </h6>
                    <h5>
                      <Currency price={-order?.delivery_discount} />
                    </h5>
                  </div>
                )}

                {(order?.is_free_delivery && order?.used_points?.transaction_id) && (
                  <div className="bill-info">
                    <h6>
                      {lang("Delivery Discount")} (
                      {`By Loyalty Points ${order?.used_points?.points}`}):
                    </h6>
                    <h5>
                      <Currency price={-order?.delivery_discount} />
                    </h5>
                  </div>
                )}

                {order?.driver_id ? (
                  <div className="bill-info">
                    <h6>
                      {lang("Platform Commission - Delivery")}
                      {`(${order?.platform_commission_rates?.driver ??
                        order?.driver_id?.commission_percentage
                        }%)`}
                    </h6>
                    <h5>
                      <Currency
                        price={order?.platform_commission?.driver ?? 0}
                      />
                    </h5>
                  </div>
                ) : null}

                {
                  <div className="bill-info">
                    <h6>{lang("Total Platform Commission")}</h6>
                    <h5>
                      <Currency
                        price={
                          (order?.platform_commission?.driver ?? 0) +
                          (order?.platform_commission?.restaurant ?? 0) ?? 0
                        }
                      />
                    </h5>
                  </div>
                }

                {order?.tip ? (
                  <div className="bill-info">
                    <h6>{lang("Tip")}</h6>
                    <h5>
                      <Currency price={order?.tip} />
                    </h5>
                  </div>
                ) : null}
              </div>

              <div className="total-price">
                <div className="bill-info">
                  <h6>{lang("TOTAL Order")}</h6>
                  <h5>
                    <Currency price={order?.total_payable} />
                  </h5>
                </div>
              </div>
            </div>
          </Col>

          <Col span={24} sm={24} lg={12}>
            <div className="order-dtl-card">
              <div className="order-header">
                <h3>{lang("Delivery")}</h3>
              </div>
              <div className="customer-dtl">
                <div className="bill-info">
                  <h6>{lang("Status")}</h6>
                  <h5 className="cap">{orderStatus}</h5>
                </div>
                <div className="bill-info">
                  <h6>{lang("Order Type")}</h6>
                  <h5>{lang(order?.type)}</h5>
                </div>
                <div className="bill-info">
                  <h6>{lang("Payment Mode")}</h6>
                  <h5 style={{ textTransform: "uppercase" }}>
                    {/* {order?.payment_mod === "cod"
                        ? "Cash on Delivery"
                        : order?.payment_mod} */}
                    {
                      lang(order?.payment_mod)
                    }
                  </h5>
                </div>

                <div className="bill-info">
                  <h6>{lang("Delivery Distance")}</h6>
                  <h5>
                    {order?.distance
                      ? `${order?.distance.toFixed(1)}`
                      : "0 "} {lang("K.M")}
                  </h5>
                </div>

                {
                  order?.vehicle_type ?
                    <div className="bill-info">
                      <h6>{lang("Driver Vehicle Type")}</h6>
                      <h5>
                        {
                          lang(order.vehicle_type)
                        }
                      </h5>
                    </div> : null
                }

              </div>
            </div>

            {order?.driver_id ? (
              <div className="order-dtl-card">
                <div className="order-header">
                  <h3>{lang("Delivery Agent Details")}</h3>
                </div>
                <div className="customer-dtl">
                  <div className="delivery-agent-dtl">
                    <div className="agent-img">
                      <img src={order?.driver_id?.image} />
                    </div>
                    <div className="agent-info">
                      <div className="customer-info">
                        <h6>{lang("Delivery Status")}:</h6>
                        <h5>{deliveryStatus}</h5>
                      </div>
                      <div className="customer-info">
                        <h6>{lang("Name")} :</h6>
                        <h5>{data?.driver_id?.name}</h5>
                      </div>
                      <div className="customer-info">
                        <h6>{lang("Phone Number")} :</h6>
                        <h5>
                          <Link
                            className="social-media-sec"
                            to={`https://wa.me/${data?.driver_id?.country_code + data?.driver_id?.mobile_number}`}
                            target="_blank"
                          >
                            {formatPhone(
                              data?.driver_id?.country_code,
                              data?.driver_id?.mobile_number,
                            )}
                          </Link>
                        </h5>
                      </div>

                      {order?.driver_id?.vehicle?.rc_number ? (
                        <div className="customer-info">
                          <h6>{lang("Vehicle No. ")}:</h6>
                          <h5>{order?.driver_id?.vehicle?.rc_number}</h5>
                        </div>
                      ) : (
                        ""
                      )}

                      {order?.rating?.driver ?
                        <div className="customer-info">
                          <h6>{lang("Rating")} :</h6>
                          <h5>{data?.rating?.driver}</h5>
                        </div> : null}

                    </div>
                  </div>
                </div>
              </div>
            ) : null
            }

          </Col>
        </Row>
      </Modal>

    </>
  );
};


const OrderItem = ({ item }) => {

  const [quantity, setQuantity] = useState(item?.qty);
  const { language } = useAppContext()

  useEffect(() => {
    setQuantity(item?.qty);
  }, [item]);

  return (
    <React.Fragment>
      <div className="single-order-dtl mb-0">
        <div className="order-middle">
          <h4>
            {quantity} x
            {language !== "en" && language !== null
              ? item?.food_id?.[`${language}_name`] ??
              item?.food_id?.name
              : item?.food_id?.name}
          </h4>

          <p>
            {language !== "en" && language !== null
              ? item?.size_id?.[`${language}_name`] ??
              item?.size_id?.name
              : item?.size_id?.name}
          </p>

          {
            (item?.choice && item.choice.length) ? (
              <>
                {item.choice.map(ch =>
                  <div key={ch.choice_id._id}>
                    <p
                      style={{
                        fontSize: "14px",
                        margin: "5px 0px",
                        fontWeight: 600,
                      }}
                    >
                      <h6
                        style={{
                          fontSize: "14px",
                          fontWeight: 600,
                          margin: "10px 0px 0px",
                        }}
                      >
                        ||
                        {/* {ch.choice_id.name}  */}
                        {ch.choice_id.name &&
                          ` ${language !== "en" &&
                            language !== null
                            ? ch.choice_id?.[
                            `${language}_name`
                            ] ?? ch.choice_id?.name
                            : ch.choice_id?.name
                          } `}
                        ||
                      </h6>
                    </p>
                    <ul>
                      {/* {
                          ch.options.map((op, index) => (
  
                            <li className="order-option" key={index}>
                              <div
                                className="product-item-inn"
  
                              >
                                {op}
                              </div>
                            </li>
                          ))} */}

                      {ch.options.map((op, index) => {
                        if (typeof op == 'string') {
                          return (
                            <li className="order-option" key={index}>
                              <div className="product-item-inn">{op}</div>
                            </li>
                          )
                        } else {
                          return (
                            <li className="order-option" key={index}>
                              <div className="product-item-inn">
                                {
                                  language !== "en" && language !== null
                                    ? op?.[`${language}_name`] ??
                                    op?.name
                                    : op?.name
                                }
                              </div>
                            </li>)
                        }
                      })}

                    </ul>
                  </div>
                )}
              </>
            ) : null
          }


        </div>
        <div className="order-right" style={{ flex: "auto" }}>
          <h4>
            {/* <Currency price={item?.total_price ?? item?.price} /> */}
            {item?.food_id?.name ? (
              <span style={{ color: "black" }}>
                <span>{item?.qty}</span> X <Currency price={item?.price} /> ={" "}
                <Currency price={item?.item_price} />
              </span>
            ) : (
              ""
            )}
          </h4>
        </div>
      </div>
      <div className="single-order-dtl">
        <div className="order-middle" style={{ width: "100%" }}>
          {item?.add_on_with_qty && item?.add_on_with_qty?.length > 0 && (
            <>
              {Object.entries(
                item?.add_on_with_qty.reduce((accumulator, addOn) => {
                  const category =
                    language !== "en" && language !== null
                      ? addOn?.add_cat_id?.[
                      `${language}_name`
                      ] ?? addOn?.add_cat_id?.name
                      : addOn?.add_cat_id?.name;

                  if (!accumulator[category]) {
                    accumulator[category] = [];
                  }
                  accumulator[category].push(addOn);
                  return accumulator;
                }, {}),
              ).map(([category, addOns], categoryIndex) => (
                <div key={categoryIndex}>
                  <p
                    style={{
                      fontSize: "14px",
                      margin: "5px 0px",
                      fontWeight: 600,
                    }}
                  >
                    <h6
                      style={{
                        fontSize: "14px",
                        fontWeight: 600,
                        margin: "10px 0px 0px",
                      }}
                    >
                      || {category} ||
                    </h6>
                  </p>

                  {addOns.map((addOn, index) => (
                    <div key={index}>
                      {addOn?.ingredient_ids.map(
                        (ingredient, ingredientIndex) => (
                          <p
                            key={ingredientIndex}
                            style={{
                              fontSize: "14px",
                              margin: "0px",
                              fontWeight: 500,
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <p>
                              {ingredient?.qty ? (
                                <strong>{ingredient?.qty}X</strong>
                              ) : (
                                ""
                              )}{" "}

                              {/* {ingredient?.id?.name &&
                                ` ${ingredient?.id?.name} `}
                              {ingredient?.size?.name !== "Regular"
                                ? `(${ingredient?.size?.name})`
                                : ""} */}

                              {ingredient?.id?.name &&
                                ` ${language !== "en" &&
                                  language !== null
                                  ? ingredient?.id?.[
                                  `${language}_name`
                                  ] ??
                                  ingredient?.id?.name
                                  : ingredient?.id?.name
                                } `}

                              {ingredient?.size?.name !==
                                "Regular"
                                ? `(${language !== "en" &&
                                  language !== null
                                  ? ingredient?.size?.[
                                  `${language}_name`
                                  ] ??
                                  ingredient?.size
                                    ?.name
                                  : ingredient?.size
                                    ?.name
                                })`
                                : ""}

                            </p>

                            {ingredient?.price && (
                              <span style={{ color: "black" }}>
                                <span>{ingredient?.qty}</span> X{" "}
                                <Currency price={ingredient?.price} /> ={" "}
                                <Currency
                                  price={ingredient?.price * ingredient?.qty}
                                />
                              </span>
                            )}
                          </p>
                        ),
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </>
          )}

        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewDetailModal;
