import { UndoOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Select, Table, Tabs, Tooltip } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import Lottie from "react-lottie";
import * as success from "../../assets/animation/success.json";
import Currency from "../../components/Currency";
import { DownloadExcel } from "../../components/ExcelFile";
import apiPath from "../../constants/apiPath";
import { Months } from "../../constants/var";
import { AppStateContext } from "../../context/AppContext";
import { dateString } from "../../helper/functions";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";

const Search = Input.Search;
const { TabPane } = Tabs;

const RestaurantWithdrawal = ({}) => {
  const { country } = useContext(AppStateContext);
  const sectionName = "Restaurant Payment Withdraw Approval";
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const { request } = useRequest();

  const [filter, setFilter] = useState({
    driver_id: undefined,
    city_id: undefined,
    year: undefined,
    month: undefined,
    // status: 'Pending',
    payment_mod: undefined,
  });

  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);
  const [restaurants, setRestaurant] = useState([]);
  const [cities, setCities] = useState([]);

  const checkFilterValue = () => {
    console.log("filtervalue", filter, filter.year);
    if (filter.year && filter.month) {
      DownloadExcel(excelData, sectionName);
    } else {
      ShowToast("Please select a year and months", Severty.ERROR);
    }
  };

  const getFilter = () => {
    request({
      url: `${apiPath.finance}/driver/filters/withdraw/vendor`,
      method: "GET",
      onSuccess: (res) => {
        const { data, months, years, restaurants } = res;
        setCities(data);
        setYears(years);
        setRestaurant(restaurants);
        const m = Months.filter((item) => months.includes(item.value));
        setMonths(m);
      },
    });
  };

  useEffect(() => {
    getFilter();
  }, []);

  const onChange = (key, value) => {
    setFilter((prev) => ({ ...prev, [key]: value }));
  };

  const excelData = list.map((row) => ({
    Restaurant_Id: row?.user_id?.uid || "-",
    Name: row?.user_id?.name || "-",
    Total_amount: `${country?.data?.currency_symbol} ` + (row?.amount || 0),
    Status: row?.status || "-",
    RequestDate: row?.created_at ? moment(row?.created_at).format("lll") : "-",
  }));

  const api = {
    approve: apiPath.finance + "/driver/vendor-withdraw/approve",
    list: apiPath.finance + "/driver/vendor-withdraw/",
  };

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const handleChange = (pagination, sorter, filters) => {
    // if (!sorter) {
      fetchData(pagination);
    // }
  };

  const fetchData = (pagination, status) => {
    setLoading(true);

    const payload = { ...filter };
    payload.page = pagination ? pagination.current : 1;
    payload.pageSize = pagination ? pagination?.pageSize : 10;

    const queryString = Object.entries(payload)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");

    request({
      url: api.list + `${queryString ? `?${queryString}` : ""}`,
      // url: api.list,

      method: "GET",
      onSuccess: ({ data, total, status }) => {
        setLoading(false);
        if (status) {
          setList(data);
          setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            total: total,
          }));
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const paid = (id) => {
    setLoading(true);

    request({
        url: api.approve + `/${id}`,
        method: "POST",
        onSuccess: ({ data, total, status, message }) => {
            setLoading(false);
            if (status) {
                showModal()
                setTimeout(() => setRefresh(prev => !prev), 200)
            } else {
                ShowToast(message, Severty.ERROR)
            }

        },
        onError: (error) => {
            setLoading(false);
            ShowToast(error, Severty.ERROR);
        },
    });
  };

  useEffect(() => {
    setLoading(true);
    fetchData({current:1});
  }, [refresh, country.country_id, filter]);

  const columns = [
    {
      title: "Restaurant ID",
      dataIndex: "id",
      render: (_, { user_id }) => `#${user_id ? user_id.uid : ""}`,
    },
    {
      title: "NAME",
      dataIndex: "name",
      key: "name",
      render: (_, { user_id }) => `${user_id ? user_id.name : ""}`,
    },
    {
      title: "withdrawal Date",
      dataIndex: "dateTime",
      key: "dateTime",
      render: (_, { created_at }) => `${dateString(created_at, "ll")}`,
    },
    {
      title: "Requested withdrawal Amount",
      dataIndex: "withdrawalAmount",
      key: "withdrawalAmount",
      render: (_, { amount }) => <Currency price={amount ?? 0} />,
    },
    {
      title: "Reason",
      dataIndex: "Reason",
      key: "Reason",
      render: (_, { reason }) => reason,
    },
    {
      title: "Restaurant Wallet Balance",
      dataIndex: "WalletBalance",
      key: "WalletBalance",
      render: (_, { wallet }) => <Currency price={wallet?.balance ?? 0} />,
    },

    {
      title: "Action",
      fixed: "right",
      render: (_, record) => {
        return (
          <>
            {record.status == "Pending" ? (
              <Tooltip title={lang("Pay")} color={"purple"} key={"viewDetail"}>
                <Button
                  className="ms-sm-2 mt-xs-2 primary_btn btnStyle"
                  onClick={(e) => paid(record._id)}
                >
                  {lang("Pay")}
                </Button>
              </Tooltip>
            ) : (
              <Button className="ms-sm-2 mt-xs-2 primary_btn btnStyle">
                {lang("Paid")}
              </Button>
            )}
          </>
        );
      },
    },
  ];
  console.log(columns,"columns----------")
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: success,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <div className="tab_inner_tit">
        <div className="tab-upload-wrap d-flex align-items-center justify-content-between">
          <h3>{lang("Resturant Withdraw Approval")}</h3>
          <div className="d-flex align-items-center gap-3">
            {/* <div className="city-wrap">
                <Select
                  width="110"
                  value={filter.country_id}
                  placeholder="Country"
                  showSearch
                  filterOption={(input, option) =>
                    option.label
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value) => {
                    getCities(value);
                    onChange("country_id", value);
                  }}
                  options={countries.map((item) => ({
                    value: item._id,
                    label: item.name,
                  }))}
                />
              </div> */}
            <div className="city-wrap">
              <Select
                style={{ minWidth: "150px" }}
                placeholder={lang("Restaurant")}
                showSearch
                value={filter.driver_id}
                // filterOption={false}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={restaurants.map((item) => ({
                  value: item._id,
                  label: item.name,
                }))}
                onChange={(value) => onChange("driver_id", value)}
              />
            </div>

            <div className="city-wrap">
              <Select
                width="250"
                style={{ minWidth: "150px" }}
                placeholder="Status"
                value={filter.status}
                options={[
                  { value: "Pending", label: "Pending" },
                  { value: "Approved", label: "Approved" },
                  //   { value: "Reject", label: "Rejected" },
                ]}
                onChange={(value) => onChange("status", value)}
              />
            </div>
            <div className="role-wrap">
              <Select
                last20Years
                width="110px"
                placeholder={lang("City")}
                showSearch
                value={filter.city_id}
                // filterOption={false}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={cities.map((item) => ({
                  value: item._id,
                  label: item.name,
                }))}
                //onPopupScroll={handleScroll}
                // onSearch={(newValue) => setSearchCity(newValue)}
                onChange={(value) => onChange("city_id", value)}
              />
            </div>
            <div className="role-wrap">
              <Select
                width="110px"
                placeholder={lang("Year")}
                showSearch
                value={filter.year}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={years.map((item) => ({
                  value: item,
                  label: item,
                }))}
                onChange={(value) => onChange("year", value)}
              />
            </div>
            <div className="role-wrap">
              <Select
                width="110px"
                placeholder={lang("Month")}
                showSearch
                value={filter.month}
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                options={months.map((item) => ({
                  ...item,
                }))}
                onChange={(value) => onChange("month", value)}
              />
            </div>

            <Button
              onClick={() =>
                setFilter({
                  country_id: undefined,
                  city_id: undefined,
                  year: undefined,
                  month: undefined,
                  status: undefined,
                })
              }
              type="primary"
              icon={<UndoOutlined />}
            >
              {lang("Reset")}
            </Button>

            <div className="btn_grp">
              <Button
                className="primary_btn btnStyle"
                onClick={() => checkFilterValue()}
              >
                {lang("Export to Excel")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      {/* <h4 className="text-right mb-1">{pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}</h4> */}

      <div className="table-responsive customPagination withOutSearilNo">
        <Table
          loading={loading}
          columns={columns}
          dataSource={list}
          pagination={{
            defaultPageSize: 10,
            responsive: true,
            total: pagination.total,
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "30", "50"],
          }}
          onChange={handleChange}
          className="ant-border-space"
        />
      </div>
      {/* <Modal
            open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
            width={750}
            okText="Approve"
            cancelText={"Decline"}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="tab_modal"
          >
          <h4 className="modal_title_cls">Withdrawal Payment</h4> 
          <div className="order-head">
            <div className="withdrawer-img">
              <img src={withDrawerImg} />
            </div>
            <div className="withdrawer_name">
              <h4>Frank Murlo</h4>
            </div>
          </div>
          <Form>
            <Row gutter={24}>
                <Col span={24} md={24}>
                  <Form.Item className="recepit-number"
                    label="Upload Receipt Image"
                  >
                    <InputNumber placeholder="" />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item className="recepit-number"
                    label="Receipt Number"
                  >
                    <InputNumber placeholder="Enter Receipt Number" />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item className="recepit-number"
                    label="Total Withdrawal Amount (AED)"
                  >
                    <InputNumber placeholder="200" />
                  </Form.Item>
                </Col>
            </Row>
          </Form>
        </Modal> */}
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={650}
        okText="Okay"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="tab_modal"
      >
        <Lottie options={defaultOptions} height={120} width={120} />
        <h4 className="modal_title_cls mb-2 mt-3 payment-app-text">{`Payment Approved`}</h4>
        {/* <p className="modal_link_inner mb-0   payment-text">Lorem ipsum dolor sit amat, consectetur adipiscing elit</p> */}
      </Modal>
    </>
  );
};

export default RestaurantWithdrawal;
