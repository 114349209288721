import { Card, Col, Row, Tabs } from "antd";
import React, { useContext, useEffect, useState } from "react";

import { AppStateContext } from "../../context/AppContext";
import useRequest from "../../hooks/useRequest";
import { ContentManagerForm } from "./ContentManagerForm";
import BannerManager from "./_BannerManager";
import lang from "../../helper/langHelper";
import EmailTemplate from "../EmailTemplate/Index"
import FAQList from "../FAQs";
import LoyaltyPoints from "../LoyaltyPoints";
const { TabPane } = Tabs;
  
const cmsTabs = {
  BANNER: lang("App Banner Management"),
  CONTENT: lang("Page Content Management"),
  ADD: lang("Email Templates"),
  LOAYAL:lang("Loyalty Points Management"),
  FAQ:lang("FAQ"),
};

function Index() {
  const { setPageHeading } = useContext(AppStateContext);

  const { request } = useRequest();

  const urlParams = new URLSearchParams(window.location.search);
  const path = urlParams.get("path");

  const [selectedTab, setSelectedTab] = useState(path ?? cmsTabs.CONTENT);

  const handleTabChange = (status) => {
    setSelectedTab(status);
  };

  useEffect(() => {
    setPageHeading(lang("CMS Management"));
  }, []);

  return (
    <>
      <div className="tabled quoteManagement">
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24}>
            <Card bordered={false} className="criclebox tablespace mb-24">
              <Tabs
                className="main_tabs"
                onTabClick={handleTabChange}
                activeKey={selectedTab}
                tabBarStyle={{ color: "green" }}
              >
                {/* <TabPane tab={cmsTabs.BANNER} key={cmsTabs.BANNER}>
                  <div style={{ padding: "40px" }}></div>
                </TabPane> */}

                <TabPane tab={cmsTabs.CONTENT} key={cmsTabs.CONTENT}>
                  <div className="cms-bodycontent"  >
                    <ContentManagerForm />
                  </div>
                </TabPane>  

                <TabPane tab={cmsTabs.LOAYAL} key={cmsTabs.LOAYAL}>
                  <LoyaltyPoints />
                </TabPane>

                <TabPane tab={cmsTabs.FAQ} key={cmsTabs.FAQ}>
                  <FAQList />
                </TabPane>

                <TabPane tab={cmsTabs.ADD} key={cmsTabs.ADD}>
                  <EmailTemplate />
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Index;
