import { MinusCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  TimePicker
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import EditIcon from "../../assets/images/edit.svg";
import deleteWhiteIcon from "../../assets/images/icon/deleteWhiteIcon.png";
import {
  SelectInput,
  TextInputBox
} from "../../components/InputField";
import apiPath from "../../constants/apiPath";
import { Palestine } from "../../constants/var";
import { useAppContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useRequest from "../../hooks/useRequest";
import UploadImage from "../User/Customer/_UploadImage";
import LocationMap from "../User/LocationMap";

const weekdays = [
  { name: "sunday", label: "S" },
  { name: "monday", label: "M" },
  { name: "tuesday", label: "T" },
  { name: "wednesday", label: "W" },
  { name: "thursday", label: "Th" },
  { name: "friday", label: "F" },
  { name: "saturday", label: "ST" },
];

const ViewCompany = ({ show, hide, data, refresh, deleteRestaurant }) => {

  const api = {
    restaurant: apiPath.company,
    country: apiPath.common.countries,
    city: apiPath.common.city,
    fc: apiPath.common.foodCategories,
    rc: apiPath.common.restaurantCategories,
  };

  const { country } = useAppContext();
  const [form] = Form.useForm();
  const { request } = useRequest();
  const [isFeature, setIsFeature] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [areas, setAreas] = useState([]);
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [restaurantCategories, setRestaurantCategories] = useState([]);
  const [isAllRestaurants, setIsAllRestaurants] = useState(false);
  const [cities, setCities] = useState([]);
  const [document, setDocument] = useState();
  const [logo, setLogo] = useState();
  const [mobileNumber, setMobileNumber] = useState(null);
  const [mobileNumber2, setMobileNumber2] = useState({});
  const [location, setLocation] = useState(null);
  const [editLocation, setEditLocation] = useState(null);
  const [restaurants, setRestaurants] = useState([]);
  //for edit
  const [deleteModal, showDeleteModal] = useState(false);
  const { language } = useAppContext();


  const getRestaurants = (cities) => {
    request({
      url: `/restaurants/` + country.country_id,
      method: "POST",
      data: { cities: cities },
      onSuccess: ({ data, status }) => {
        if (status) {
          setRestaurants(data);
        }
      },
    });
  };

  const getAreas = (id) => {
    request({
      url: `/city-area/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "setCities");
        if (data) {
          const ar_areas = data.map((item) => {
            return { ...item, name: item.ar_name }
          })
          setAreas(language === "en" ? data : ar_areas);
        }
      },
    });
  };

  useEffect(() => {
    //getCountry();
    // getCities();
    // getCategory();
    // getFoodCategory();
  }, []);

  const getCities = (id) => {
    request({
      url: `/country-city/${id}`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "setCities");
        if (data) {
          const ar_cities = data.map((item) => {
            return { ...item, name: item.ar_name }
          })
          setCities(language === "en" ? data : ar_cities);
        }
      },
    });
  };

  useEffect(() => {
    if (!data) return;
    const { location, ...rest } = data;

    form.setFieldsValue({
      ...rest,
      close_time: moment(rest.close_time),
      open_time: moment(rest.open_time),
      time: [moment(rest.open_time), moment(rest.close_time)],
      country_id: rest?.country_id?._id,
      city_id: rest?.city_id?._id,
      area: rest?.area?._id,
      location_on_map: rest.address,
      start_date: moment(rest.start_date),
      break_slots:data.break_slots.map(item=>({
        slot_time: moment(item.slot_time)
      }))
    }); 
    getRestaurants([rest?.city_id?._id]);
    setIsAllRestaurants(data.for_all_restaurants)

    setLogo(rest.logo);

    console.log(rest.image, "Image");
    setLocation({
      map_address: rest.map_address,
      latitude: rest.latitude,
      longitude: rest.longitude,
    });
    setEditLocation({
      map_address: rest.map_address,
      latitude: rest.latitude,
      longitude: rest.longitude,
    });
    getCities(rest?.country_id?._id);
    getAreas(rest?.city_id?._id);

  }, [data]);

  const onCreate = (values) => {
    const { location_on_map, time, area } = values;
    let payload = {
      ...values,
      ...mobileNumber,
      open_time: time[0],
      close_time: time[1],
      ...mobileNumber2,
      ...location,
      // image: image,
      image: image,
      country_id: country.country_id,
      area,
    };
    setLoading(true);
    if (logo) payload.logo = logo;
    if (document) payload.document = document;
    request({
      url: `${api.restaurant}/${data._id}`,
      method: "PUT",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status) {
          ShowToast(data.message, Severty.SUCCESS);
          hide();
          refresh();
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error.response.data.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };


  const handleLocationChange = (value) => {
    console.log(value, "valuevaluevalue");
    setLocation({
      map_address: value.map_address,
      latitude: value.lat ?? value?.latitude,
      longitude: value.lng ?? value?.longitude,
    });
  };

  return (
    <Modal
      open={show}
      width={1400}
      okText={lang("Save")}
      footer={null}
      onCancel={hide}
      okButtonProps={{
        form: "create",
        htmlType: "submit",
        loading: loading,
      }}
      className="tab_modal"
    >
      <Form
        initialValues={{
        
        }}
        id="create"
        form={form}
        // disabled={!isEdit}
        onFinish={onCreate}
        layout="vertical"
      >
        <Row gutter={[16, 16]}>
          <Col sm={24}>
            <Row gutter={[16]}>
              <Col span={24} lg={24} xs={24}>
                <div className="row g-3">
                  <div className="col-12 col-sm-6">
                    <h4 className="modal_title_cls mb-2 modal_title_menu text-start">
                      {data.name}
                    </h4>

                  </div>
                  <div className="col-12 col-sm-6 d-flex align-items-center gap-3 justify-content-end">
                    <Button
                      onClick={() => setIsEdit(true)}
                      className="edit-cls btnStyle primary_btn   d-flex align-items-center"
                    >
                      <img src={EditIcon} />
                      {lang("Edit")}
                    </Button>

                    <Button
                      onClick={deleteRestaurant}
                      className="edit-cls btnStyle deleteDangerbtn py-2 px-3 d-flex align-items-center gap-1"
                    >
                      <img src={deleteWhiteIcon} /> {lang("Delete")}{" "}
                    </Button>
                  </div>
                </div>
              </Col>

              <Col span={24} lg={6} xs={24}>
                <Card className="">
                  <div className="row g-3">
                    <Col span={24}>
                      <Form.Item
                        className="upload_wrap"
                        rules={[
                          {
                            validator: (_, value) => {
                              if (logo) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error("Profile image is required"),
                              );
                            },
                          },
                        ]}
                        name="logo"
                      >
                        <UploadImage
                          disabled={!isEdit}
                          value={logo}
                          setImage={setLogo}
                        />
                      </Form.Item>
                    </Col>

                  </div>
                </Card>
              </Col>


            </Row>
          </Col>

          <Col span={24} lg={14} xs={24} className="d-flex flex-column gap-3">
            <Card className="">
              <div className="row g-3">
                <TextInputBox
                  isDisable={!isEdit}
                  name="name"
                  label={lang("Company Name")}
                  placeholder={lang("Enter Company Name")}
                  rules={[
                    {
                      max: 250,
                      message: lang(
                        "Name should contain more then 250 characters!",
                      ),
                    },
                    {
                      required: true,
                      message: lang("Please Enter Company Name"),
                    },
                  ]}
                />

                <TextInputBox
                  isDisable={!isEdit}
                  name="ar_name"
                  label={lang("Company Name Arabic")}
                  placeholder={lang("Enter Company Name")}
                  rules={[
                    {
                      max: 250,
                      message: lang(
                        "Name should contain more then 250 characters!",
                      ),
                    },
                    {
                      required: true,
                      message: lang("Please Enter Company Name"),
                    },
                  ]}
                />

                <div className="col-xxl-6 col-lg-12 col-md-6">
                  <Form.Item
                    className="checkBox_wrap"
                    label={lang("Working Days")}
                    name="working_days"
                    rules={[{
                      required: true,
                      message: lang('Missing Working Days Selection!')
                    }]}
                  >
                    <Checkbox.Group
                      disabled={!isEdit}
                      onChange={(value) => console.log(value, "working")}
                    >
                      {weekdays.map((item, idx) => (
                        <Checkbox value={item.name} key={idx}>
                          {" "}
                          {item.label}{" "}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>
                  </Form.Item>
                </div>
                <div className="col-xxl-6 col-lg-12 col-md-6 px-0">
                  <Row gutter={20}>
                    <Col span={24} md={24}>
                      <Form.Item
                        label={lang("Working Time")}
                        name="time"
                        rules={[
                          {
                            required: true,
                            message: lang("Please select working time"),
                          },
                        ]}
                      >
                        <TimePicker.RangePicker
                          disabled={!isEdit}
                          format="h:mm a"
                          placeholder={[lang("Open Time"), lang("Close Time")]}
                          use12Hours
                          order={false}
                        />
                      </Form.Item>
                    </Col>

                  </Row>
                </div>
              </div>
            </Card>
            <Card>
              <Row gutter={[16, 16]} className="g-3">
                <SelectInput
                  disabled={!isEdit}
                  name="city_id"
                  label={lang("City")}
                  placeholder={lang("Select City")}
                  showSearch
                  handleChange={(value) => {
                    getAreas(value);
                    getRestaurants([value]);
                    form.setFieldsValue({ area: null, for_all_restaurants: true, restaurants: [] });
                  }}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={cities}
                  cover={{ md: 5 }}
                  colProps={{ sm: 24, span: 24 }}
                  rules={[
                    { required: true, message: lang("Missing Type Selection") },
                  ]}
                />

                {country.country_id == Palestine.id && (
                  <SelectInput
                    disabled={!isEdit}
                    name="area"
                    label={lang("Area")}
                    placeholder={lang("Select Area")}
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0
                    }
                    options={areas} //TODO:  change to area
                    /**
                                     *  options={areas.map(item=>({
                                      _id:item.name,
                                      name:item.name
                                    }))} 
                                     */
                    cover={{ md: 12 }}
                    colProps={{ sm: 24, span: 24 }}
                    rules={[
                      {
                        required: country.country_id == Palestine.id,
                        message: lang("Missing Type Selection"),
                      },
                    ]}
                  />
                )}

                {/* <TextInputBox
                                    isDisable={!isEdit}
                                    name="area"
                                    label="Area"
                                    placeholder="Enter Area"
                                    rules={[
                                        {
                                            max: 20,
                                            message:
                                                "Area should contain more then 20 characters!",
                                        },

                                    ]}
                                /> */}

                <TextInputBox
                  isDisable={!isEdit}
                  name="address"
                  label={lang("Address")}
                  placeholder={lang("Enter Address")}
                  colProps={{ sm: 24, span: 24 }}
                  rules={[
                    {
                      max: 600,
                      message: lang(
                        "Address should contain more then 600 characters!",
                      ),
                    },
                    {
                      required: true,
                      message: lang("Please Enter Address"),
                    },
                  ]}
                />
                <TextInputBox
                  isDisable={!isEdit}
                  name="ar_address"
                  label={lang("Address Arabic")}
                  placeholder={lang("Enter Address")}
                  colProps={{ sm: 24, span: 24 }}
                  rules={[
                    {
                      max: 600,
                      message: lang(
                        "Address should contain more then 600 characters!",
                      ),
                    },
                    {
                      required: true,
                      message: lang("Please Enter Address"),
                    },
                  ]}
                />
              </Row>
            </Card>
            <Card>
              <Row gutter={[16, 16]} className="g-3">
                <Col span={24} md={8}>
                  <Form.Item
                    label={lang("For All Vendors")}
                    name="for_all_restaurants"
                    // initialValue={false}
                    valuePropName="checked"
                  >
                    <Checkbox   disabled={!isEdit} onChange={(e) => setIsAllRestaurants(e.target.checked)}></Checkbox>
                  </Form.Item>
                </Col>


                <SelectInput
                  allowClear
                  mode="multiple"
                  name="restaurants"
                  label={lang("Vendors")}
                  placeholder={lang("Select Vendors")}
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                  }
                  disabled={isAllRestaurants}
                  options={restaurants} //TODO:  change to area
                  cover={{ md: 12 }}
                  colProps={{ sm: 24, span: 24 }}
                  rules={[
                    {
                      required: !isAllRestaurants,
                      message: lang("Missing Vendors Selection"),
                    },
                  ]}
                />

              </Row>
            </Card>

            <Card>
              <Row gutter={[16, 16]} className="g-3">
                <Col span={24}>
                  <Form.List label={lang('Break Slot')} name="break_slots">
                    {(fields, { add, remove }) => (
                      <Row gutter={[16, 16]} className="g-3">
                        {fields.map(({ key, name, ...restField }, index) => (
                          <>
                            <Col span={14}>
                              <Form.Item
                                {...restField}
                                name={[name, 'slot_time']}
                                rules={[{
                                  required: true,
                                  message: 'Missing Slot Selection'
                                }]}
                              >
                                <TimePicker
                                  format="h:mm a"
                                  placeholder={`${lang("Slot Time")} ${index + 1}`}
                                  use12Hours 
                                  disabled={!isEdit}
                                />
                              </Form.Item>


                            </Col>
                            {fields.length != 1 ?
                              <Col span={6}>
                                <MinusCircleOutlined onClick={() => remove(name)} />
                              </Col> : null}

                          </>
                        ))}
                        <Button type="dashed" onClick={() => add()} block>
                          Add Input
                        </Button>
                      </Row>
                    )}
                  </Form.List>
                </Col>
              </Row>
            </Card>

          </Col>


          <Col span={24} lg={10} xs={24}>
            <Card>
              <Row gutter={[16, 16]}>
                <Col span={24} md={12}>
                  <Form.Item
                    name="code"
                    label={lang("Company Code")}
                    placeholder={lang("Enter Company Code")}
                    cover={{ md: 10 }}
                    rules={[
                      {
                        required: true,
                        message: lang("Missing Company Code"),
                      },
                    ]}
                    normalize={(value) => value.toUpperCase()}
                  >
                    <Input    disabled={!isEdit} placeholder={lang("Enter Company Code")} />
                  </Form.Item>
                </Col>

                <Col md={12} sm={24} span={24}>
                  <Form.Item
                    name="start_date"
                    label={lang("Code Start Date")}
                    rules={[
                      {
                        required: true,
                        message: lang("Missing Start Date Selection"),
                      },
                    ]}
                  >
                    <DatePicker   disabled={!isEdit} />
                  </Form.Item>
                </Col>
                <Col span={24} md={12}>
                  <Form.Item
                    name="box_count"
                    label={lang("Number of Box")}
                    rules={[
                      {
                        required: true,
                        message: lang("Please Enter Number of Box"),
                      },
                    ]}
                  >
                    <InputNumber
                      // formatter={(value) => `${value}%`}
                      // parser={(value) => value.replace("%", "")}
                      min={0}
                      max={100}
                      disabled={!isEdit}
                      placeholder={lang("Enter Number of Box")}
                    />
                  </Form.Item>
                </Col>

                <Col span={24} md={12}>
                  <Form.Item
                    name="delivery_fee"
                    label={`${lang("Delivery Fee for employee")} (${country.data.currency})`}
                    rules={[
                      {
                        required: true,
                        message: lang("Please Enter Delivery Fee for employee"),
                      },
                    ]}
                  >
                    <InputNumber
                      // formatter={(value) => `${value}%`}
                      // parser={(value) => value.replace("%", "")}
                      max={100}
                      placeholder="10 "
                      min={0} 
                      disabled={!isEdit}
                    />
                  </Form.Item>
                </Col>

              </Row>

            </Card>

            <Row className="">
              <Col span={24} md={24} className="mt-5">
                <Form.Item
                  disabled={!isEdit}
                  label={lang("Location (Drag Marker for Selecting Location)")}
                  name="location_on_map"
                  rules={[
                    {
                      required: true,
                      message: lang("Please select the location!"),
                    },
                  ]}
                >
                  <LocationMap
                    className="mt-3"
                    onChange={handleLocationChange}
                    userData={location}
                    editLocation={editLocation}
                  />
                </Form.Item>
              </Col>
            </Row>

            <div className="d-flex align-items-center gap-3 justify-content-end mt-5">
              {isEdit ? (
                <>
                  <Button
                    onClick={hide}
                    className=" btnStyle btnOutlineDelete "
                  >
                    {lang("Cancel")}
                  </Button>
                  <Button loading={loading} className="primary_btn btnStyle " htmlType="submit">
                    {lang("SUBMIT")}
                  </Button>
                </>
              ) : (
                <Button onClick={hide} className="primary_btn btnStyle ">
                  {lang("Okay")}
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ViewCompany;
