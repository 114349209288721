import { UndoOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Modal,
  Radio,
  Row,
  Select,
  Switch,
  Table,
  Tabs,
  Tooltip,
  Input
} from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import notfound from "../../assets/images/not_found.png";
import Plus from "../../assets/images/plus.svg";
import ConfirmationBox from "../../components/ConfirmationBox";
import DeleteModal from "../../components/DeleteModal";
import SectionWrapper from "../../components/SectionWrapper";
import apiPath from "../../constants/apiPath";
import { BlockRest, Months } from "../../constants/var";
import { AppStateContext, useAppContext } from "../../context/AppContext";
import { Severty, ShowToast } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import AddForm from "./AddForm";
import ViewAndEditModal from "./View";
import ViewNewRestaurantRequest, { ApproveStatus } from "./_ViewNewRequest";
import ViewRequest from "./_ViewRequest";
import ViewLogs from "../../components/ViewLogs";
import lang from "../../helper/langHelper";
import { DeleteRestaurantReasons } from "../../constants/reasons";

const { TabPane } = Tabs;

function Index() {
  const heading = lang("Restaurant") + " " + lang("Management");
  const { setPageHeading } = useContext(AppStateContext);

  const [selectedTab, setSelectedTab] = useState("Restaurant_List");

  useEffect(() => {
    setPageHeading(heading);
  }, []);

  const handleTabChange = (status) => {
    setSelectedTab(status);
    // fetchData(pagination, '', status)
  };

  return (
    <>
      <div className="tabled quoteManagement">
        <Row gutter={[24, 0]}>
          <Col xs={24} xl={24}>
            <Card bordered={false} className="criclebox tablespace mb-24">
              <Tabs
                className="main_tabs"
                onTabClick={handleTabChange}
                activeKey={selectedTab}
                tabBarStyle={{ color: "green" }}
              >
                <TabPane tab={lang("Restaurant List")} key="Restaurant_List">
                  <RestaurantList />
                </TabPane>

                <TabPane
                  tab={lang("APPROVAL PENDING")}
                  key={"APPROVAL_PENDING"}
                >
                  <Approval />
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

const RestaurantList = () => {

  const sectionName = lang("Restaurant");
  const routeName = "restaurant";
  const heading = sectionName + " " + lang("Management");
  const { setPageHeading, country } = useContext(AppStateContext);

  const api = {
    status: apiPath.restaurant + "/status",
    list: apiPath.restaurant,
  };

  const { request } = useRequest();
  const [list, setList] = useState([]);
  const { showConfirm } = ConfirmationBox();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [visible, setVisible] = useState(false);
  const [logs, showLogs] = useState(false);
  const [viewVisible, setViewVisible] = useState(false);
  const [selected, setSelected] = useState();
  const [searchText, setSearchText] = useState("");
  const [deleteModal, showDeleteModal] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 300);
  const [searchCity, setSearchCity] = useState("");
  const debouncedSearchCity = useDebounce(searchCity, 300);
  const [page, setPage] = useState(1);
  const [selectedTab, setSelectedTab] = useState("Restaurant_List");
  const [statusModal, setstatusModal] = useState(false);

  const navigate = useNavigate();

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const { language } = useAppContext();

  const [filter, setFilter] = useState({
    country_id: undefined,
    city_id: undefined,
    year: undefined,
    month: undefined,
    status: undefined
  });

  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);

  const getFilter = () => {
    request({
      url: `${api.list}/filters`,
      method: "GET",
      onSuccess: (res) => {
        const { data, months, years } = res;
        setCities(data);
        setYears(years);
        const m = Months.filter((item) => months.includes(item.value));
        setMonths(m);
      },
    });
  };

  // useEffect(() => {
  //   getCountry();
  // }, []);

  // useEffect(() => {
  //   if (!country.country_id) return
  //   setCities([])
  //   getCities(country.country_id, 1);
  // }, [country.country_id]);

  const getCountry = () => {
    request({
      url: `/country`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "Country");
        if (data) {
          setCountries(data);
        }
      },
    });
  };

  const getCities = (id, page, search = false) => {
    if (!id) return;
    request({
      url: `/country-city/${id}?page=${page}&pageSize=200${search ? `&search=${search}` : ""
        }`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "setCities");
        if (data) {
          setCities((prev) => [...data]);
          //search ? setCities(prev => ([...data])) : setCities(prev => ([...prev, ...data]));
        }
      },
    });
  };

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollHeight - scrollTop === clientHeight && !loading) {
      setPage((prevPage) => prevPage + 1);
    }
  };
  const view = (id) => {
    navigate(`/${routeName}/view/${id}`);
  };

  useEffect(() => {
    setLoading(true);
    fetchData({ ...pagination });
    getFilter();
  }, [refresh]);

  useEffect(() => {
    if (!list.length || loading) return

    setLoading(true);
    fetchData({ ...pagination, current: 1 });
    // getFilter();
  }, [debouncedSearchText, filter, country.country_id]);

  const columns = [
    {
      title: lang("Name"),
      dataIndex: "name",
      key: "name",
      render: (_, { name }) => {
        return name ? <span className="cap">{name}</span> : "-";
      },
    },
    {
      title: lang("Arabic Name"),
      dataIndex: "name",
      key: "name",
      render: (_, { ar_name }) => {
        return ar_name ? <span className="cap">{ar_name}</span> : "-";
      },
    },
    {
      title: lang("Phone Number"),
      dataIndex: "mobile_number",
      key: "mobile_number",
      render: (_, { mobile_number, country_code }) => {
        return mobile_number && country_code
          ? `(${country_code}) ${mobile_number}`
          : "-";
      },
    },
    {
      title: lang("Email"),
      dataIndex: "email",
      key: "email",
      render: (_, { email }) => {
        return email ? email : "-";
      },
    },
    {
      title: lang("Status"),
      dataIndex: "email",
      key: "email",
      render: (_, { vendor_id }) => {
        return vendor_id?.is_online ? lang("Online") : lang("Offline");
      },
    },
    {
      title: lang("Joining Date"),
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return moment(created_at).format("DD-MMM-YYYY");
      },
    },
    // {
    //   title: "Member Type",
    //   dataIndex: "member_type",
    //   key: "member_type",
    //   render: (_, { member_type }) => {
    //     return member_type ? member_type : "Restaurant";
    //   },
    // },
    // {
    //   title: "Country",
    //   dataIndex: "country",
    //   key: "country",
    //   render: (_, { country_id }) => {
    //     return country_id ? country_id.name : "-";
    //   },
    // },
    {
      title: lang("City"),
      dataIndex: "city",
      key: "city",
      render: (_, { city_id }) => {
        const currentLang = language || "en";
        if (currentLang == 'en') {
          return city_id ? city_id.name : "-";
        } else {
          return city_id ? city_id?.ar_name : "-";
        }
      },
    },
    {
      title: lang("Area"),
      dataIndex: "area",
      key: "area",
      render: (_, { area }) => {
        const currentLang = language || "en";
        if (currentLang == 'en') {
          return area ? area?.name : "-";
        } else {
          return area ? area?.ar_name : "-";
        }
      },
    },
    {
      title: lang("Address"),
      dataIndex: "address",
      key: "address",
      width: 50,
      render: (_, { address, ar_address }) => {
        const currentLang = language || "en";
        if (currentLang == 'en') {
          return address ? address : "-";
        } else {
          return address ? ar_address : "-";
        }
      },
    },
    {
      title: lang("Action"),
      fixed: "right",
      render: (_, record) => {
        return (
          <>
            <Tooltip
              title={lang("View")}
              color={"purple"}
              key={"view" + routeName}
            >
              <Button
                className="ms-sm-2 mt-xs-2 primary_btn btnStyle"
                onClick={(e) => {
                  setSelected(record);
                  setViewVisible(true);
                }}
              >
                {lang("View")}{" "}
              </Button>
            </Tooltip>
            <Tooltip
              title={lang("Logs")}
              color={"purple"}
              key={"view" + routeName}
            >
              <Button
                className="btnStyle btnOutlineDelete"
                onClick={(e) => {
                  setSelected(record);
                  showLogs(true);
                }}
              >
                {lang("Logs")}{" "}
              </Button>
            </Tooltip>
          </>
        );
      },
    },
    {
      title: lang("Status"),
      key: "is_active",
      dataIndex: "is_active",
      render: (_, { _id, is_active }) => {
        return (
          <Switch
            onChange={() => {
              setSelected({ _id, is_active });
              showModal();
              //  handleChangeStatus(_id);
            }}
            checked={is_active}
          />
        );
      },
    },
  ];

  useEffect(() => {
    setPageHeading(heading);
  }, []);

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null;

    const queryString = Object.entries(filter)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      )
      .join("&");

    request({
      url:
        api.list +
        `?page=${pagination ? pagination.current : 1
        }&search=${debouncedSearchText}&pageSize=${pagination ? pagination.pageSize : 10}${queryString ? `&${queryString}` : ""
        }`,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        console.log(data, "data");
        setList(data);
        setPagination((prev) => ({ ...pagination, total: total }));
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const onChange = (key, value) => {
    if (key == "country_id") {
      setCities([]);
      setFilter((prev) => ({ ...prev, city_id: undefined, country_id: value }));
    } else {
      setFilter((prev) => ({ ...prev, [key]: value }));
    }
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  };

  const showModal = () => {
    setstatusModal(true);
  };

  const handleCancel = () => {
    setstatusModal(false);
  };

  const handleChangeStatus = (id, message) => {
    request({
      url: `${api.list}/${id}/status`,
      method: "PUT",
      data: { message },
      onSuccess: (data) => {
        setLoading(false);
        setSelected();
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const onDelete = (id) => {
    request({
      url: api.list + "/" + id,
      method: "DELETE",
      onSuccess: (data) => {
        setLoading(false);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <>
      <SectionWrapper
        cardHeading={lang(`Restaurant List`)}
        extra={
          <>
            {/* <div className="button_group justify-content-end w-100"> */}
            <div className="w-100 d-flex align-items-baseline text-head_right_cont">
              {/* <div className="city-wrap">
                <Select
                  width="110"
                  value={filter.country_id}
                  placeholder="Country"
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value) => {
                    getCities(value);
                    onChange("country_id", value);
                  }}
                  options={countries.map((item) => ({
                    value: item._id,
                    label: item.name,
                  }))}
                />
              </div> */}
              <div className="pageHeadingSearch">
                <Input.Search
                  className="searchInput"
                  placeholder={lang(
                    "Search by vendor name, Phone number, email ,address",
                  )}
                  onChange={onSearch}
                  allowClear
                />
              </div>
              <div className="role-wrap">
                <Select
                  last20Years
                  width="110px"
                  placeholder={lang("City")}
                  showSearch
                  value={filter.city_id}
                  //filterOption={false}

                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={cities.map((item) => ({
                    value: item._id,
                    label: item.name,
                  }))}
                  // onPopupScroll={handleScroll}
                  // onSearch={(newValue) => setSearchCity(newValue)}
                  onChange={(value) => onChange("city_id", value)}
                />
              </div>
              <div className="role-wrap">
                <Select
                  width="110px"
                  placeholder={lang("Year")}
                  showSearch
                  value={filter.year}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={years.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                  onChange={(value) => onChange("year", value)}
                />
              </div>
              <div className="role-wrap">
                <Select
                  width="110px"
                  placeholder={lang("Month")}
                  showSearch
                  value={filter.month}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={months.map((item) => ({ ...item }))}
                  onChange={(value) => onChange("month", value)}
                />
              </div>
              <div className="role-wrap">
                <Select
                  width="110px"
                  placeholder={lang("Status")}
                  value={filter.status}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={[{
                    label: lang("Online"),
                    value: 'online'
                  },
                  {
                    label: lang("Offline"),
                    value: 'offline'
                  }
                  ]}
                  allowClear
                  onChange={(value) => onChange("status", value)}
                />
              </div>
              <Button
                onClick={() =>
                  setFilter({
                    country_id: undefined,
                    city_id: undefined,
                    year: undefined,
                    month: undefined,
                  })
                }
                type="primary"
                icon={<UndoOutlined />}
              >
                {lang("Reset")}
              </Button>
              <Button
                className="ms-sm-2 mt-xs-2 primary_btn btnStyle"
                onClick={(e) => {
                  setVisible(true);
                  // setSearchText("");
                }}
              >
                <span className="add-Ic d-flex">
                  <img src={Plus} />
                </span>
                {lang("Add")} {sectionName}
              </Button>
            </div>
          </>
        }
      >
        {/* <h4 className="text-right mb-1cont-space cont-space">
                {pagination.total ? ShowTotal(pagination.total) : ShowTotal(0)}
              </h4> */}
        <div className="table-responsive customPagination withOutSearilNo">
          <Table
            loading={loading}
            columns={columns}
            dataSource={list}
            pagination={{
              defaultPageSize: 10,
              responsive: true,
              total: pagination.total,
              showSizeChanger: true,
              showQuickJumper: true,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
            onChange={handleChange}
            className="ant-border-space"
          />
        </div>
      </SectionWrapper>

      {visible && (
        <AddForm
          section={sectionName}
          api={api}
          show={visible}
          hide={() => {
            setSelected();
            setVisible(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}

      {viewVisible && (
        <ViewAndEditModal
          section={sectionName}
          api={api}
          show={viewVisible}
          hide={() => {
            setSelected();
            setViewVisible(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
          deleteRestaurant={() => {
            showDeleteModal(true);
            setViewVisible(false);
          }}
          viewLogs={() => {
            showLogs(true);
          }}
        />
      )}

      {logs && (
        <ViewLogs
          data={selected.vendor_id}
          show={logs}
          hide={() => {
            showLogs(false);
            setSelected();
          }}
        />
      )}

      {deleteModal && (
        <DeleteModal
          title={lang("Delete Restaurant")}
          subtitle={lang(`Are you sure you want to Delete this restaurant?`)}
          show={deleteModal}
          hide={() => {
            showDeleteModal(false);
            setSelected();
          }}
          reasons={DeleteRestaurantReasons}
          onOk={() => onDelete(selected?._id)}
        />
      )}

      {statusModal && (
        <DeleteModal
          title={lang("Restaurant Status")}
          subtitle={`${lang(`Are you sure you want to`)} ${selected?.is_active ? lang(`Deactivate`) : lang("Activate")
            } ${lang(`this restaurant?`)}`}
          show={statusModal}
          hide={() => {
            handleCancel();
            setSelected();
          }}
          reasons={selected?.is_active ? BlockRest : []}
          onOk={(message) => handleChangeStatus(selected?._id, message)}
        />
      )}
    </>
  );
};

const Approval = () => {
  const { country } = useContext(AppStateContext);

  const api = {
    status: apiPath.restaurant,
    addEdit: apiPath.restaurant,
    list: apiPath.restaurant,
  };

  const [searchText, setSearchText] = useState("");
  const { request } = useRequest();
  const { showConfirm } = ConfirmationBox();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [refresh, setRefresh] = useState(false);
  const debouncedSearchText = useDebounce(searchText, 300);
  const [selectedDriver, setSelectedDriver] = useState();
  const [searchCity, setSearchCity] = useState("");
  const debouncedSearchCity = useDebounce(searchCity, 300);
  const [page, setPage] = useState(1);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [filter, setFilter] = useState({
    country_id: undefined,
    city_id: undefined,
    year: undefined,
    month: undefined,
  });

  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);

  const getFilter = () => {
    request({
      url: `${api.list}/filters`,
      method: "GET",
      onSuccess: (res) => {
        const { data, months, years } = res;
        setCities(data);
        setYears(years);
        const m = Months.filter((item) => months.includes(item.value));
        setMonths(m);
      },
    });
  };

  // useEffect(() => {
  //   if (!country.country_id) return
  //   setCities([])
  //   getCities(country.country_id, 1);
  // }, [country.country_id]);

  const getCountry = () => {
    request({
      url: `/country`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "Country");
        if (data) {
          setCountries(data);
        }
      },
    });
  };

  const getCities = (id, page, search = false) => {
    if (!id) return;
    request({
      url: `/country-city/${id}?page=${page}&pageSize=200${search ? `&search=${search}` : ""
        }`,
      method: "GET",
      onSuccess: ({ data, status }) => {
        console.log(data, "setCities");
        if (data) {
          setCities((prev) => [...data]);
          //search ? setCities(prev => ([...data])) : setCities(prev => ([...prev, ...data]));
        }
      },
    });
  };

  const onChange = (key, value) => {
    if (key == "country_id") {
      setCities([]);
      setFilter((prev) => ({ ...prev, city_id: undefined, country_id: value }));
    } else {
      setFilter((prev) => ({ ...prev, [key]: value }));
    }
  };

  const handleChangeStatus = (id) => {
    request({
      url: api.status + "/" + id,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        setRefresh(true);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const approvalColumns = [
    {
      title: lang("Rest. ID"),
      dataIndex: "index",
      key: "index",
      width: 200,
      render: (_, { vendor_id }) =>
        vendor_id ? <span className="cap">#{vendor_id.uid}</span> : "-",
    },
    {
      title: lang("name"),
      dataIndex: "image",
      key: "image",
      width: 200,
      render: (_, { logo, name }) => (
        <>
          <Image
            width={40}
            height={40}
            src={logo ? logo : notfound}
            className="table-img "
          />
          {name ? <span className="cap">{name}</span> : "-"}
        </>
      ),
    },
    {
      title: lang("Country"),
      dataIndex: "country_id",
      key: "country_id",
      render: (_, { country_id }) => {
        return country_id ? (
          <span className="cap">{country_id.name}</span>
        ) : (
          "-"
        );
      },
    },
    {
      title: lang("City"),
      dataIndex: "city_id",
      key: "city_id",
      render: (_, { city_id }) => {
        return city_id ? <span className="cap">{city_id.name}</span> : "-";
      },
    },
    {
      title: lang("Document"),
      dataIndex: "id_document",
      key: "id_document",
      render: (_, { document }) => (
        <Image
          // width={50}
          src={document.length ? document[0] : notfound}
          className="table-img image-doc"
        />
      ),
    },
    {
      title: lang("Request date"),
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return moment(created_at).format("ll");
      },
    },
    {
      title: lang("Action"),
      fixed: "right",
      key: "action",
      render: (_, record, { approve_status }) => {
        return (
          <>
            {record.approve_status === ApproveStatus.PENDING ? (
              <Tooltip
                title={lang("Waiting for Approval")}
                color={"purple"}
                key={lang("Edit")}
              >
                <Button
                  title={lang("Edit")}
                  className="btnStyle btnOutlineDelete"
                  onClick={() => {
                    setSelectedDriver(record);
                    setVisible(true);
                  }}
                >
                  {lang("Waiting for Approval")}
                </Button>
              </Tooltip>
            ) : (
              <Tooltip
                title={lang("Approval Rejected")}
                color={"purple"}
                key="Approval Rejected"
                onClick={() => {
                  setSelectedDriver(record);
                  setVisible(true);
                }}
              >
                <Button title={lang("Edit")} className="block-cls cap">
                  {lang("Rejected")}
                </Button>
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    fetchData(pagination);
    getFilter();
  }, [refresh, debouncedSearchText, filter, country.country_id]);

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null;

    const queryString = Object.entries(filter)
      .filter(([_, v]) => v)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      )
      .join("&");

    request({
      url:
        api.list +
        "/pending" +
        `?status=${filterActive ? filterActive.join(",") : ""}&page=${pagination ? pagination.current : 1
        }&pageSize=${pagination.pageSize ?? 10}&search=${debouncedSearchText}${queryString ? `&${queryString}` : ""
        }`,
      method: "GET",
      onSuccess: ({ data, status, total }) => {
        setLoading(false);
        if (status) {
          setList(data);

          setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            total: total,
          }));
        }
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  };

  const onSearch = (e) => {
    setSearchText(e.target.value);
    setPagination({ current: 1 });
  };

  return (
    <>
      <SectionWrapper
        cardHeading={lang(`Restaurant Approval Pending List`)}
        cardSubheading={""}
        extra={
          <>
            <div className="w-100 text-head_right_cont">
              {/* <div className="city-wrap">
                <Select
                  width="110"
                  value={filter.country_id}
                  placeholder="Country"
                  showSearch
                  filterOption={(input, option) =>
                    option.label
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(value) => {
                    getCities(value);
                    onChange("country_id", value);
                  }}
                  options={countries.map((item) => ({
                    value: item._id,
                    label: item.name,
                  }))}
                />
              </div> */}
              <div className="role-wrap">
                <Select
                  last20Years
                  width="110px"
                  placeholder={lang("City")}
                  showSearch
                  value={filter.city_id}
                  //filterOption={false}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={cities.map((item) => ({
                    value: item._id,
                    label: item.name,
                  }))}
                  //onPopupScroll={handleScroll}
                  // onSearch={(newValue) => setSearchCity(newValue)}
                  onChange={(value) => onChange("city_id", value)}
                />
              </div>
              <div className="role-wrap">
                <Select
                  width="110px"
                  placeholder={lang("Year")}
                  showSearch
                  value={filter.year}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={years.map((item) => ({
                    value: item,
                    label: item,
                  }))}
                  onChange={(value) => onChange("year", value)}
                />
              </div>
              <div className="role-wrap">
                <Select
                  width="110px"
                  placeholder={lang("Month")}
                  showSearch
                  value={filter.month}
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={months.map((item) => ({
                    ...item,
                  }))}
                  onChange={(value) => onChange("month", value)}
                />
              </div>

              <Button
                onClick={() =>
                  setFilter({
                    country_id: undefined,
                    city_id: undefined,
                    year: undefined,
                    month: undefined,
                    status: undefined
                  })
                }
                type="primary"
                icon={<UndoOutlined />}
              >
                {lang("Reset")}
              </Button>
            </div>
          </>
        }
      >
        <div className="table-responsive customPagination">
          <Table
            loading={loading}
            columns={approvalColumns}
            dataSource={list}
            pagination={{
              defaultPageSize: 10,
              responsive: true,
              total: pagination.total,
            }}
            onChange={handleChange}
            className="ant-border-space"
          />
        </div>

        {visible && (
          <ViewRequest
            section={lang("Driver")}
            api={api}
            show={visible}
            hide={() => setVisible(false)}
            data={selectedDriver}
            refresh={() => setRefresh(!refresh)}
          />
        )}
      </SectionWrapper>
    </>
  );
};

export default Index;
