import React from 'react';
import { Form, Input, Button } from 'antd';
import lang from '../helper/langHelper';

const LoyaltyPointsForm = ({ onSubmit, formValues, setFormValues }) => {
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    const handleSubmit = () => {
        onSubmit(formValues);
    };

    return (
        <Form layout="vertical" style={{ marginTop: '24px' }}>
            <Form.Item
                label={`${lang("Conversion Cash to Points")} (${lang('ex:')} ${lang('Order amount')} 57 > ${lang('Loyalty Point Earned')} = 57 /# = 5.7 ${lang('points')})`}
                rules={[{ required: true, message: lang("Please input the loyalty points conversion rate!") }]}
            >
                <Input
                    name="conversionRate"
                    value={formValues.conversionRate}
                    onChange={handleChange}
                    placeholder={lang("Enter loyalty points conversion rate")}
                />
            </Form.Item>
            <Form.Item
                label={`${lang("Conversion Points to Cash")} (${lang('ex:')} ${lang('Earned 35 points')}  >> 35 /# = ${`ILS`} 3.5 ${lang('points')}) 35 ${lang('points')}/ ${`ILS`} 100 = 0.35 ${lang('cash')}`}
                rules={[{ required: true, message: lang("Please input the conversion rate for points used for delivery!") }]}
            >
                <Input
                    name="deliveryConversionRate"
                    value={formValues.deliveryConversionRate}
                    onChange={handleChange}
                    placeholder={lang("Enter conversion rate for delivery")}
                />
            </Form.Item> 
            <Form.Item
                label={lang("Reward Referral point")}
                rules={[{ required: true, message: lang("Please input the referral points for reward!") }]}
            >
                <Input
                    name="refer_point"
                    value={formValues.refer_point}
                    onChange={handleChange}
                    placeholder={lang("Enter referral points for reward")}
                />
            </Form.Item>

            <Form.Item
                label={`${lang("Point Expiry Time")} (${lang('Months')})`}
                rules={[{ required: true, message: lang("Please input the point expiry time!") }]}
            >
                <Input
                    name="expiryTime"
                    value={formValues.expiryTime}
                    onChange={handleChange}
                    placeholder={lang("Enter point expiry time")}
                />
            </Form.Item>
            <Form.Item>
                <Button type="primary" onClick={handleSubmit}>
                    {lang("Submit")}
                </Button>
            </Form.Item>
        </Form>
    );
};

export default LoyaltyPointsForm;
