import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import { Upload } from '@aws-sdk/lib-storage';
import apiPath from '../constants/apiPath';

const s3Config = {
    region: 'ap-south-1',
    credentials: {
        accessKeyId: "B8ESG93XA59WD5N2H2SJ",
        secretAccessKey: "5HLMmSrAfEWuPByapd3lVMZBaynTNkfAKlTZZn0L",
    },
    endpoint: "https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080",
    forcePathStyle: true,
};

const s3Client = new S3Client(s3Config)

export const uploadToS3 = async ({ file, type = "images" }) => {
    const key = "tawasi/" + type + `/${file.name}`

    const params = {
        Bucket: 'tawasi-s3',
        Key: key,
        Body: file,
        ContentType: file.type,
    };

    const upload = new Upload({
        client: s3Client,
        params: params,
    });

    try {
        const data = await upload.done();
        
        const response = {
            location: apiPath.assetURL + data.Key
        }
        console.log(data,response)
        return response;
    } catch (err) {
        throw err;
    }
};